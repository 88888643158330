import React from 'react';

import StudentEventSidebar from '../../components/StudentEventSidebar';

import {connect} from 'react-redux';
import {checkEventCode, loadEvent, standardEventUpdates, submitSolution} from '../../store/student-events';

import config from '../../config/default.json';

import "./styles.css";
import LoadingSpinner from "../../components/LoadingSpinner";

import {UnControlled as CodeMirror} from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/clike/clike';


let languages = [

    {name: 'c', title: 'C (gcc)'},
    {name: 'cpp', title: 'C++ (g++)'},
    {name: 'java', title: 'javac (OpenJDK)'}/*,
    {name: 'python', title: 'Python (python3)'},
    {name: 'csharp', title: 'C# (csc)'},*/


].filter(language => (config.SUPPORTED_LANGUAGES.indexOf(language.name) >= 0));


class StudentEventItemPage extends React.Component {

    codeMirrorInstance = undefined;

    state = {
        viewingAnalysis: false,
        startingSourceCode: (languages.find(l => l.name === 'cpp') ? this.defaultSourceCode() : ''),
        sourceCode: (languages.find(l => l.name === 'cpp') ? this.defaultSourceCode() : ''),
        submissionLanguage: (languages.find(l => l.name === 'cpp') ? 'cpp' : languages[0].name),

        internalError: undefined
    };

    componentWillMount() {
        let eventId = this.props.match.params.id;

        if ((!this.props.event || this.props.event.id != eventId) && !this.props.isLoadingEvent) {
            if (this.props.event) {
                this.props.loadEvent(eventId);
            } else {
                setTimeout(() => {
                    this.props.loadEvent(eventId);
                }, 1000);
            }
        }
    }

    componentWillReceiveProps(newProps) {
        let eventId = newProps.match.params.id;
        if (newProps.event && newProps.event.id == eventId && newProps.event.format && newProps.event.status !== 'running' && newProps.event.status !== 'analysis') {
            this.props.history.push(`/student/events/${eventId}`)
        }

        if (newProps.event && newProps.event.id != eventId && !newProps.isLoadingEvent) {
            this.props.loadEvent(eventId);
        }


        let currentItemId = this.props.match.params.itemId;
        let updatedItemId = newProps.match.params.itemId;

        if (currentItemId != updatedItemId && this.codeMirrorInstance) {
            this.codeMirrorInstance.setValue(languages.find(l => l.name === 'cpp') ? this.defaultSourceCode() : '');
        }
    }


    componentDidMount() {
        let eventId = this.props.match.params.id;

        if (this.props.event && this.props.event.id == eventId) {
            this.props.standardEventUpdates(eventId);
        }

        this.intervalId = setInterval(() => {

            if (this.props.event && this.props.event.id == eventId) {
                this.props.standardEventUpdates(eventId);
            }
        }, 40 * 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }


    defaultSourceCode() {
        return (`#include <iostream>
using namespace std;

int main() {

    //your code goes here ...
    //good luck

    return 0;
}`);
    }


    changeSubmissionLanguage(language) {
        this.setState({submissionLanguage: language}, () => {
            let mode = (language === 'cpp' ? 'text/x-c++src' : (language === 'c' ? 'text/x-csrc' : 'text/x-java'));
            this.codeMirrorInstance.setOption('mode', mode);
        });
    }


    submitSolution() {

        if (this.state.sourceCode && this.state.submissionLanguage) {
            let eventId = this.props.match.params.id;
            let itemId = this.props.match.params.itemId;

            this.props.submitSolution(eventId, itemId, {
                language: this.state.submissionLanguage,
                sourceCode: this.state.sourceCode
            });
        }
    }


    formatTimeLimit(timeLimit) {
        return ('' + timeLimit).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'ms';
    }

    formatMemoryLimit(memoryLimit) {
        let limitInKB = parseInt(memoryLimit, 10);
        let limitInMB = Math.round(limitInKB / 1024);

        return limitInMB + 'MB';
    }


    render() {

        let item = undefined;
        let itemType = undefined;

        let eventId = this.props.match.params.id;
        let itemId = this.props.match.params.itemId;

        if (this.props.event && this.props.event.id == eventId) {
            itemType = (this.props.location && this.props.location.pathname && this.props.location.pathname.indexOf('article') >= 0) ? 'articles' : 'tasks';

            if (this.props.event[itemType]) {
                item = this.props.event[itemType].find(i => i.id == itemId);


                if (itemType === 'tasks' && this.state.viewingAnalysis && !((item.solutionDescription && item.solutionDescription.length >= 5) || (item.solutionSourceCode && item.solutionSourceCode.length >= 5))) {
                    this.setState({viewingAnalysis: false});
                }
            }
        }

        return (
            <div className="container mb-4">

                <div className="row">
                    <div className="col text-center">

                        {(!item) ? (
                            <div>
                                <h2 className="mb-4">{config.APP_NAME}</h2>
                                <LoadingSpinner/>
                            </div>) : ''}


                        {(item) ? (

                            <div className={"row event-item-" + (itemType || 'unknown')}>
                                <div className="col-12 col-md-4 col-lg-3">
                                    <StudentEventSidebar event={this.props.event}
                                                         pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}/>
                                </div>

                                <div className="col-12 col-md-8 col-lg-9">

                                    <div className="text-left">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <h2 className="mb-4">{item.name}</h2>

                                                {(this.state.viewingAnalysis === false && itemType === 'tasks')
                                                    ? <h5 className="mb-4" style={{ marginTop: '-1.25rem', marginLeft: '3px', opacity: 0.5, fontSize: '1rem' }}>Time limit: {this.formatTimeLimit(item.timeLimit)} &nbsp; | &nbsp; Memory limit: {this.formatMemoryLimit(item.memoryLimit)}</h5>
                                                    : ''}
                                            </div>

                                            <div className="col-md-3">

                                                {((item.solutionDescription && item.solutionDescription.length >= 5) || (item.solutionSourceCode && item.solutionSourceCode.length >= 5))
                                                    ? (
                                                        <div className="btn-group float-right mt-2" role="group">
                                                            <button type="button" onClick={(event) => {
                                                                event.preventDefault();
                                                                this.setState({viewingAnalysis: false})
                                                            }}
                                                                    className={"btn " + (!this.state.viewingAnalysis ? 'btn-info' : 'btn-secondary')}>Task
                                                            </button>
                                                            <button type="button" onClick={(event) => {
                                                                event.preventDefault();
                                                                this.setState({viewingAnalysis: true})
                                                            }}
                                                                    className={"btn " + (this.state.viewingAnalysis ? 'btn-info' : 'btn-secondary')}>Analysis
                                                            </button>
                                                        </div>
                                                    )
                                                    : ''}

                                            </div>
                                        </div>


                                        {(this.state.viewingAnalysis === false || itemType !== 'tasks')
                                            ? (<div>
                                                <div className="event-item-contents"
                                                     dangerouslySetInnerHTML={{__html: item.contentsHtml}}></div>

                                                {((this.props.event.status === 'running' || this.props.event.status === 'analysis') && itemType === 'tasks')
                                                    ? (
                                                        <div className="mt-5">
                                                            <div className="row">
                                                                <div className="col-12 col-md-8">
                                                                    <h3>Submission</h3>
                                                                </div>

                                                                <div className="col-12 col-md-4">
                                                                    <select className="form-control"
                                                                            value={this.state.submissionLanguage}
                                                                            onChange={event => {
                                                                                this.changeSubmissionLanguage(event.target.value);
                                                                            }}>
                                                                        {languages.map(l => (
                                                                            <option key={l.name}
                                                                                    value={l.name}>{l.title}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div style={{border: '1px solid #e7e7e7'}}>
                                                                <CodeMirror
                                                                    value={this.state.startingSourceCode}
                                                                    options={{
                                                                        mode: 'text/x-c++src',
                                                                        lineNumbers: true
                                                                    }}
                                                                    onChange={(editor, data, value) => {
                                                                        this.setState({sourceCode: value})
                                                                    }}

                                                                    editorDidMount={editor => {
                                                                        this.codeMirrorInstance = editor;
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="text-right mt-2">
                                                                <button className="btn btn-primary"
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            this.submitSolution();
                                                                        }}>Submit solution
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : ''}
                                            </div>)

                                            : (<div className="task-analysis-solution-source-code">

                                                <div className="event-item-contents"
                                                     dangerouslySetInnerHTML={{__html: item.solutionDescriptionHtml}}></div>


                                                {(item.solutionSourceCode && item.solutionSourceCode.length > 5)
                                                    ? (<div style={{border: '1px solid #e7e7e7', marginTop: '25px'}}>
                                                        <CodeMirror
                                                            value={item.solutionSourceCode}
                                                            options={{
                                                                readOnly: true,
                                                                mode: 'text/x-c++src',
                                                                lineNumbers: true
                                                            }}
                                                        />
                                                    </div>)
                                                    : ''}
                                            </div>)}


                                    </div>
                                </div>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        event: state.studentEvents.event,
        isLoadingEvent: state.studentEvents.isLoadingEvent,
        failedLoadingEvent: state.studentEvents.failedLoadingEvent,

        error: state.studentEvents.lastError
    }
};

function mapDispatchToProps(dispatch) {
    return {
        checkEventCode: (eventId, code) => {
            dispatch(checkEventCode(eventId, code));
        },
        loadEvent: (eventId) => {
            dispatch(loadEvent(eventId));
        },

        standardEventUpdates: (eventId) => {
            dispatch(standardEventUpdates(eventId));
        },

        submitSolution: (eventId, taskId, {language, sourceCode}) => {
            dispatch(submitSolution(eventId, taskId, {language, sourceCode}));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentEventItemPage);
