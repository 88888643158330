import React from 'react';
import {Link} from "react-router-dom";
import config from '../../config/default.json';

import './styles.css';

const HomePage = props => (
    <div className="bg-fill d-flex align-items-center">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 text-center">

                    <h2 style={{fontSize: '2.5rem'}}>{config.APP_NAME}</h2>

                    <div className="text-center d-none d-md-block">
                        <img src="/images/header1.jpg" className="small-intro-img"/>
                        <img src="/images/header2.jpg" className="small-intro-img"/>
                        <img src="/images/header3.jpg" className="small-intro-img"/>

                    </div>

                    <p style={{margin: '20px 0 90px 0', fontSize: '1.35rem'}}>Welcome to the training system for
                        IOI-like events. This system allows you to practice for various programming competitions, solve
                        algorithmic tasks and improve your coding capabilities. Have fun.</p>


                    {props.isAuthenticated
                        ? (<p>
                            <Link to="/student/events" className="btn btn-lg btn-primary">Get started</Link><br/>
                            <button className="btn btn-lg btn-link" onClick={(event) => {
                                event.preventDefault();
                                props.logoutUser();
                            }}>Log out
                            </button>
                        </p>)
                        : (<p>
                            <Link to="/auth/login" className="btn btn-lg btn-primary">Authenticate</Link><br/>
                            <Link to="/auth/signup" className="btn btn-lg btn-link">Create account</Link>
                        </p>)}

                </div>
            </div>
        </div>
    </div>
);

export default HomePage;
