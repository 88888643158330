import React from 'react';

import StudentEventSidebar from '../../components/StudentEventSidebar';

import {loadEvent, loadResults, standardEventUpdates} from '../../store/student-events';


import {connect} from 'react-redux';

import config from '../../config/default.json';
import LoadingSpinner from "../../components/LoadingSpinner";

class StudentEventResultsPage extends React.Component {

    componentWillMount() {
        let eventId = this.props.match.params.id;

        if ((!this.props.event || this.props.event.id != eventId) && !this.props.isLoadingEvent) {
            if (this.props.event) {
                this.props.loadEvent(eventId);
            } else {
                setTimeout(() => {
                    this.props.loadEvent(eventId);
                }, 1000);
            }
        }

        if (this.props.event) {
            this.loadData(this.props);
        }
    }


    componentDidMount() {
        let eventId = this.props.match.params.id;

        if (this.props.event && this.props.event.id == eventId) {
            this.props.standardEventUpdates(eventId);
        }

        this.intervalId = setInterval(() => {

            if (this.props.event && this.props.event.id == eventId) {
                this.props.standardEventUpdates(eventId);
            }
        }, 40 * 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }


    componentWillReceiveProps(newProps) {

        let eventId = this.props.match.params.id;
        if (newProps.event && newProps.event.id == eventId && newProps.event.format && newProps.event.status !== 'running' && newProps.event.status !== 'analysis') {
            this.props.history.push(`/student/events/${eventId}`)
        }

        if (newProps.event && newProps.event.id != eventId && !newProps.isLoadingEvent) {
            this.props.loadEvent(eventId);
        }

        this.loadData(newProps);
    }


    loadData(newProps) {
        let eventId = this.props.match.params.id;
        if (newProps.event && newProps.event.id == eventId && newProps.results == null && !newProps.isLoadingResults && !newProps.failedLoadingResults) {
            setTimeout(() => {
                this.props.loadResults();
            }, 500);
        }
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col text-center">

                        {(!this.props.event) ? (
                            <div>
                                <h2 className="mb-4">{config.APP_NAME}</h2>
                                <LoadingSpinner/>
                            </div>) : ''}


                        {(this.props.event && this.props.results === null) ? (
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-3">
                                    <StudentEventSidebar event={this.props.event}
                                                         pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}/>
                                </div>

                                <div className="col-12 col-md-8 col-lg-9">
                                    <LoadingSpinner/>
                                </div>
                            </div>) : ''}


                        {(this.props.event && this.props.results !== null) ? (
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-3">
                                    <StudentEventSidebar event={this.props.event}
                                                         pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}/>
                                </div>

                                <div className="col-12 col-md-8 col-lg-9">
                                    {this.renderPage()}
                                </div>
                            </div>) : ''}
                    </div>
                </div>
            </div>
        );

    }


    renderPage() {

        let previousPoints = 1e100;
        let posCounter = 0;
        let participantsCounter = 0;

        return (
            <div className="text-left">
                <h2 className="mb-4">{this.props.event.name}</h2>


                <table className="table table-striped mt-2">

                    <thead>
                    <tr>
                        <th className="text-right">#</th>
                        <th>Username</th>

                        {this.props.event.tasks.map(task => (
                            <th key={task.id} className="text-center">{task.name}</th>
                        ))}

                        <th className="text-right">Total</th>
                    </tr>
                    </thead>


                    <tbody>

                    {(this.props.results.length === 0)
                        ? (
                            <tr>
                                <td colSpan={(3 + this.props.event.tasks.length)}
                                    className="pt-4 pb-5 font-weight-bold text-center" style={{fontSize: '1.5rem'}}>
                                    No submissions found.
                                </td>
                            </tr>
                        )
                        : ''}


                    {this.props.results.map(participant => {

                        participantsCounter++;

                        if (participant.total < previousPoints) {
                            previousPoints = participant.total;
                            posCounter = participantsCounter;
                        }

                        return (
                            <tr key={participant.username}>
                                <td className="text-right">{posCounter}.</td>
                                <td>{participant.username}</td>

                                {this.props.event.tasks.map(task => (
                                    <td key={task.id}
                                        className="text-center">{(participant.tasks[task.id] !== undefined)
                                        ? (participant.tasks[task.id].points)
                                        : '/'}</td>
                                ))}

                                <td className="text-right">{participant.total}</td>
                            </tr>
                        )

                    })}

                    </tbody>
                </table>

            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        event: state.studentEvents.event,
        isLoadingEvent: state.studentEvents.isLoadingEvent,
        failedLoadingEvent: state.studentEvents.failedLoadingEvent,

        results: state.studentEvents.results,
        isLoadingResults: state.studentEvents.isLoadingResults,
        failedLoadingResults: state.studentEvents.failedLoadingResults,

        error: state.studentEvents.lastError
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadEvent: (eventId) => {
            dispatch(loadEvent(eventId));
        },
        standardEventUpdates: (eventId) => {
            dispatch(standardEventUpdates(eventId));
        },
        loadResults: () => {
            dispatch(loadResults());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentEventResultsPage);
