import React from 'react';
import RichTextEditor from 'react-rte';

import './styles.css';
import {connect} from 'react-redux';
import LoadingSpinner from "../../components/LoadingSpinner";
import UploadModal from "../../components/UploadModal";

import {createArticle, loadArticle, loadArticles, updateArticle} from '../../store/teacher-articles';
import TeacherSidebar from "../../components/TeacherSidebar";
import Card from "../../components/Card";


class TeacherAddArticlePage extends React.Component {
    state = {
        title: '',
        contents: '',

        articleId: undefined,
        loadedArticle: false,

        private: 'true',
        approved: 'false',

        uploadModalIsActive: false,
        editorValue: RichTextEditor.createEmptyValue(),
        internalError: null
    };

    componentWillMount() {

        if (!this.props.articles && !this.props.isLoadingArticles) {
            this.props.loadArticles();
        }

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            this.setState({
                articleId: this.props.match.params.id
            }, () => {

                this.props.loadArticle(this.state.articleId);
                this.loadArticleContents();
            });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.article) {
            if (nextProps && nextProps.match && nextProps.match.params && nextProps.match.params.id) {
                let newArticleId = nextProps.match.params.id;

                if (nextProps.article.id != newArticleId) {
                    this.setState({
                        articleId: newArticleId
                    }, () => {
                        this.props.loadArticle(this.state.articleId);
                    });

                } else {
                    this.loadArticleContents(nextProps);
                }
            }
        }
    }

    loadArticleContents(newProps) {
        let props = newProps || this.props;
        let articleId = parseInt(this.state.articleId, 10);

        let article = props.article;

        if (article && article.id == articleId) {

            this.setState({
                title: article.name,
                contents: article.contents,

                private: article.privateItem ? 'true' : 'false',
                approved: article.approvedByAdmin ? 'true' : 'false',

                loadedArticle: true,
                editorValue: RichTextEditor.createValueFromString(article.contents, 'markdown')
            });
        }
    }

    submitForm() {

        this.setState({internalError: null}, () => {

            if (!this.state.title || !this.state.contents) {
                this.setState({internalError: 'Please complete all fields and try again!'});
                return /* don't submit form */;
            }

            if (!this.state.articleId) {
                this.props.createArticle(this.state.title, this.state.contents, this.state.private, this.state.approved);
            } else {
                this.props.updateArticle(this.state.articleId, this.state.title, this.state.contents, this.state.private, this.state.approved);
            }

        });
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <TeacherSidebar
                            pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}
                            administrator={this.props.accountType === 'admin'}/>
                    </div>

                    <div className="col-12 col-md-8 col-lg-9">
                        {this.renderPage()}
                    </div>
                </div>
            </div>
        );
    }


    renderPage() {

        return (
            <div>

                {(this.state.articleId && !this.state.loadedArticle) ? <LoadingSpinner/> : (
                    <form className="form data-form mt-4" style={{opacity: this.props.isUpdatingArticle ? 0.3 : 1}}
                          onSubmit={(event) => {
                              event.preventDefault();
                              this.submitForm();
                          }}>
                        <h2 className="text-center mt-1 mb-4">{this.state.articleId ? 'Edit article' : 'Add article'}</h2>

                        {(this.state.internalError || this.props.failedUpdatingArticle) ?
                            <p className="form-error">{this.state.internalError || this.props.error || 'Failed to save article. Please try again.'}</p> : ''}


                        <Card title="Basic info">

                            <div className="form-group">
                                <label htmlFor="title">Title</label>
                                <input type="text" className="form-control" id="title" name="title" autoFocus
                                       placeholder="Enter a title" value={this.state.title}
                                       onChange={event => this.setState({title: event.target.value})}/>
                            </div>


                            <div className="form-group">
                                <label htmlFor="private">Article visibility</label>

                                <select className="form-control" id="private" name="private" value={this.state.private}
                                        onChange={event => this.setState({private: event.target.value})}>

                                    <option value="true">Private (only visible to you)</option>
                                    <option value="false">Public (visible to all teachers)</option>
                                </select>
                            </div>


                            {(this.props.accountType === 'admin')
                                ? (<div className="form-group">
                                    <label htmlFor="approved">Approved for public</label>

                                    <select className="form-control" id="approved" name="approved"
                                            value={this.state.approved}
                                            onChange={event => this.setState({approved: event.target.value})}>

                                        <option value="true">Publicly visible (all teachers can see it)</option>
                                        <option value="false">Not approved (visible to creator only)</option>
                                    </select>
                                </div>)
                                : ''}
                        </Card>


                        <Card title="Contents">
                            <div className="form-group">
                                <label htmlFor="content">Article contents</label>
                                <RichTextEditor
                                    className={'rich-text-editor'}
                                    value={this.state.editorValue}
                                    onChange={(value) => {
                                        this.setState({contents: value.toString('markdown'), editorValue: value});
                                    }}
                                />
                            </div>

                            <div style={{textAlign: 'right', marginTop: '7px', marginBottom: '30px'}}>
                                <a href="#" onClick={(event) => {
                                    event.preventDefault();
                                    this.setState({uploadModalIsActive: true});
                                }}>Upload image or file</a>
                                <UploadModal open={this.state.uploadModalIsActive} onClose={() => {
                                    this.setState({uploadModalIsActive: false});
                                }}/>
                            </div>
                        </Card>


                        <div className="text-right">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                )}

            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        error: state.teacherArticles.lastError,
        article: state.teacherArticles.article,
        articles: state.teacherArticles.articles,
        isUpdatingArticle: state.teacherArticles.isUpdatingArticle,
        failedUpdatingArticle: state.teacherArticles.failedUpdatingArticle,

        isLoadingArticles: state.teacherArticles.isLoadingArticles,
        failedLoadingArticles: state.teacherArticles.failedLoadingArticles,

        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadArticles: () => {
            dispatch(loadArticles());
        },
        loadArticle: (articleId) => {
            dispatch(loadArticle(articleId));
        },
        createArticle: (title, contents, privateItem, approved) => {
            dispatch(createArticle(title, contents, privateItem, approved));
        },
        updateArticle: (articleId, title, contents, privateItem, approved) => {
            dispatch(updateArticle(articleId, title, contents, privateItem, approved));
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherAddArticlePage);
