import React from 'react';
import {connect} from 'react-redux';
import {Link, Route, Switch} from 'react-router-dom';

import {withRouter} from "react-router";
import RestrictedRoute from '../../RestrictedRoute';
import ConfirmModal from '../../components/ConfirmModal';

import './styles.css';
import {logoutUser} from "../../store/auth.js";
import config from '../../config/default.json';

import HomePage from '../../routes/HomePage';
import LoginPage from '../../routes/LoginPage';
import SignupPage from '../../routes/SignupPage';

import TeacherEventsPage from '../../routes/TeacherEventsPage';
import TeacherTasksPage from '../../routes/TeacherTasksPage';
import TeacherArticlesPage from '../../routes/TeacherArticlesPage';


import AdminGradersPage from '../../routes/AdminGradersPage';
import AdminAccountsPage from '../../routes/AdminAccountsPage';
import AdminEditAccountPage from '../../routes/AdminEditAccountPage';


import TeacherAddEventPage from '../../routes/TeacherAddEventPage';
import TeacherAddTaskPage from '../../routes/TeacherAddTaskPage';
import TeacherAddArticlePage from '../../routes/TeacherAddArticlePage';

import TeacherEventResultsPage from '../../routes/TeacherEventResultsPage';
import TeacherEventSubmissionPage from '../../routes/TeacherEventSubmissionPage';
import TeacherEventQuestionsPage from '../../routes/TeacherEventQuestionsPage';


import StudentEventPage from '../../routes/StudentEventPage';
import StudentEventsPage from '../../routes/StudentEventsPage';

import StudentEventSubmissionPage from '../../routes/StudentEventSubmissionPage';
import StudentEventSubmissionsPage from '../../routes/StudentEventSubmissionsPage';

import StudentEventResultsPage from '../../routes/StudentEventResultsPage';
import StudentEventQuestionsPage from '../../routes/StudentEventQuestionsPage';
import StudentEventItemPage from '../../routes/StudentEventItemPage';


class App extends React.PureComponent {

    state = {
        navbarToggle: false,
        logoutModalIsOpen: false
    };

    logoutUser() {
        this.props.logoutUser();
    }


    componentDidUpdate(prevProps) {
        if (this.props.location && prevProps && this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        let requestPath = ((this.props.location && this.props.location.pathname) ? this.props.location.pathname : '/');

        return (
            <div style={{
                height: '100%',
                paddingTop: (requestPath !== '/' ? '120px' : '0'),
                backgroundColor: (requestPath !== '/' ? '#ffffff' : '#000000')
            }}
                 className={(this.props.location && this.props.location.pathname !== '/' ? 'standard-page' : 'cool-background-image')}>

                <ConfirmModal content="Are you sure that you want to logout?" open={this.state.logoutModalIsOpen}
                              onConfirm={() => {
                                  this.setState({logoutModalIsOpen: false});
                                  this.logoutUser();
                              }}
                              onCancel={() => {
                                  this.setState({logoutModalIsOpen: false});
                              }}/>

                <nav className="navbar fixed-top navbar-expand-md navbar-dark bg-primary">
                    <div className="container">
                        <Link className="navbar-brand py-3" to="/">
                            <img src="/images/winner.png" width="30" height="30"
                                 className="d-inline-block align-top mr-1"
                                 alt="Winner"/>
                            {config.APP_NAME}
                        </Link>

                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarResponsive" onClick={() => {
                            this.setState({navbarToggle: !this.state.navbarToggle});
                        }}>
                            <span className="navbar-toggler-icon px-3 py-3"></span>
                        </button>
                        <div className={"collapse navbar-collapse" + (this.state.navbarToggle ? ' show' : '')}
                             id="navbarResponsive">
                            <ul className="navbar-nav ml-auto">
                                <li className={"nav-item" + ((requestPath.indexOf('teacher/') < 0 && requestPath.indexOf('student/') < 0) ? " active" : "")}>
                                    <Link className="nav-link" to="/" onClick={() => {
                                        this.setState({navbarToggle: false});
                                    }}>Home</Link>
                                </li>
                                <li className={"nav-item" + ((requestPath.indexOf('student/') >= 0) ? " active" : "")}>
                                    <Link className="nav-link" to="/student/events" onClick={() => {
                                        this.setState({navbarToggle: false});
                                    }}>Events</Link>
                                </li>

                                {this.props.isAuthenticated && (this.props.accountType === 'teacher' || this.props.accountType === 'admin')
                                    ? (
                                        <li className={"nav-item" + ((requestPath.indexOf('teacher/') >= 0) ? " active" : "")}>
                                            <Link className="nav-link" to="/teacher/events" onClick={() => {
                                                this.setState({navbarToggle: false});
                                            }}>Administration</Link>
                                        </li>
                                    )
                                    : ''}

                                {this.props.isAuthenticated
                                    ? (
                                        <li className="nav-item">
                                            <a href="/" className="nav-link" onClick={(event) => {
                                                event.preventDefault();
                                                this.setState({navbarToggle: false, logoutModalIsOpen: true});
                                            }}>Logout</a>
                                        </li>
                                    )
                                    : ''}
                            </ul>
                        </div>
                    </div>
                </nav>

                <div style={{height: '100%'}}>
                    <Switch>
                        <Route exact path="/" render={(routeProps) => <HomePage {...routeProps}
                                                                                isAuthenticated={this.props.isAuthenticated}
                                                                                logoutUser={() => {
                                                                                    this.logoutUser();
                                                                                }}/>}/>
                        <RestrictedRoute exact path="/auth/login" allow={!this.props.isAuthenticated} fallback="/"
                                         component={LoginPage}/>
                        <RestrictedRoute exact path="/auth/signup" allow={!this.props.isAuthenticated} fallback="/"
                                         component={SignupPage}/>


                        <RestrictedRoute exact path="/student/events" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={StudentEventsPage}/>
                        <RestrictedRoute exact path="/student/events/:id" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={StudentEventPage}/>
                        <RestrictedRoute exact path="/student/events/:id/questions" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={StudentEventQuestionsPage}/>

                        <RestrictedRoute exact path="/student/events/:id/submission/:submissionId"
                                         allow={this.props.isAuthenticated} fallback="/auth/login"
                                         component={StudentEventSubmissionPage}/>
                        <RestrictedRoute exact path="/student/events/:id/submissions" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={StudentEventSubmissionsPage}/>
                        <RestrictedRoute exact path="/student/events/:id/results" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={StudentEventResultsPage}/>


                        <RestrictedRoute exact path="/student/events/:id/article/:itemId"
                                         allow={this.props.isAuthenticated} fallback="/auth/login"
                                         component={StudentEventItemPage}/>
                        <RestrictedRoute exact path="/student/events/:id/task/:itemId"
                                         allow={this.props.isAuthenticated} fallback="/auth/login"
                                         component={StudentEventItemPage}/>


                        <RestrictedRoute exact path="/admin/graders" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={AdminGradersPage}/>
                        <RestrictedRoute exact path="/admin/accounts" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={AdminAccountsPage}/>
                        <RestrictedRoute exact path="/admin/accounts/edit/:id" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={AdminEditAccountPage}/>


                        <RestrictedRoute exact path="/teacher/events" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={TeacherEventsPage}/>

                        <RestrictedRoute exact path="/teacher/tasks" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={TeacherTasksPage}/>

                        <RestrictedRoute exact path="/teacher/articles" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={TeacherArticlesPage}/>


                        <RestrictedRoute exact path="/teacher/tasks/add" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={TeacherAddTaskPage}/>
                        <RestrictedRoute exact path="/teacher/tasks/edit/:id" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={TeacherAddTaskPage}/>


                        <RestrictedRoute exact path="/teacher/articles/add" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={TeacherAddArticlePage}/>
                        <RestrictedRoute exact path="/teacher/articles/edit/:id" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={TeacherAddArticlePage}/>


                        <RestrictedRoute exact path="/teacher/events/add" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={TeacherAddEventPage}/>
                        <RestrictedRoute exact path="/teacher/events/edit/:id" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={TeacherAddEventPage}/>
                        <RestrictedRoute exact path="/teacher/events/:id/questions" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={TeacherEventQuestionsPage}/>
                        <RestrictedRoute exact path="/teacher/events/:id/results" allow={this.props.isAuthenticated}
                                         fallback="/auth/login" component={TeacherEventResultsPage}/>

                        <RestrictedRoute exact path="/teacher/events/:id/submissions/:submissionId"
                                         allow={this.props.isAuthenticated} fallback="/auth/login"
                                         component={TeacherEventSubmissionPage}/>
                    </Switch>
                </div>
            </div>
        )
    }
}


const mapStateToProps = function (state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {
        logoutUser: () => {
            dispatch(logoutUser());
        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
