import React from 'react';
import {Link} from 'react-router-dom';

import StudentEventSidebar from '../../components/StudentEventSidebar';

import {loadEvent, standardEventUpdates} from '../../store/student-events';
import {loadSubmissions} from '../../store/student-submissions';


import {connect} from 'react-redux';

import config from '../../config/default.json';
import LoadingSpinner from "../../components/LoadingSpinner";

class StudentEventSubmissionsPage extends React.Component {

    componentWillMount() {
        let eventId = this.props.match.params.id;

        if ((!this.props.event || this.props.event.id != eventId) && !this.props.isLoadingEvent) {
            if (this.props.event) {
                this.props.loadEvent(eventId);
            } else {
                setTimeout(() => {
                    this.props.loadEvent(eventId);
                }, 1000);
            }
        }

        if (this.props.event) {
            this.loadData(this.props);
        }
    }


    componentDidMount() {
        let eventId = this.props.match.params.id;

        if (this.props.event && this.props.event.id == eventId) {
            this.props.standardEventUpdates(eventId);
        }

        this.intervalId = setInterval(() => {

            if (this.props.event && this.props.event.id == eventId) {
                this.props.standardEventUpdates(eventId);
            }
        }, 40 * 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }


    componentWillReceiveProps(newProps) {

        let eventId = this.props.match.params.id;
        if (newProps.event && newProps.event.id == eventId && newProps.event.format && newProps.event.status !== 'running' && newProps.event.status !== 'analysis') {
            this.props.history.push(`/student/events/${eventId}`)
        }

        if (newProps.event && newProps.event.id != eventId && !newProps.isLoadingEvent) {
            this.props.loadEvent(eventId);
        }

        this.loadData(newProps);
    }


    loadData(newProps) {
        let eventId = this.props.match.params.id;
        if (newProps.event && newProps.event.id == eventId && newProps.submissions == null && !newProps.isLoadingSubmissions && !newProps.failedLoadingSubmissions) {
            setTimeout(() => {
                this.props.loadSubmissions();
            }, 500);
        }
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col text-center">

                        {(!this.props.event) ? (
                            <div>
                                <h2 className="mb-4">{config.APP_NAME}</h2>
                                <LoadingSpinner/>
                            </div>) : ''}


                        {(this.props.event && this.props.submissions === null) ? (
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-3">
                                    <StudentEventSidebar event={this.props.event}
                                                         pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}/>
                                </div>

                                <div className="col-12 col-md-8 col-lg-9">
                                    <LoadingSpinner/>
                                </div>
                            </div>) : ''}


                        {(this.props.event && this.props.submissions !== null) ? (
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-3">
                                    <StudentEventSidebar event={this.props.event}
                                                         pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}/>
                                </div>

                                <div className="col-12 col-md-8 col-lg-9">
                                    {this.renderPage()}
                                </div>
                            </div>) : ''}
                    </div>
                </div>
            </div>
        );

    }


    allSubmissions() {

        let submissions = [];

        (this.props.submissions || []).forEach(taskInfo => {

            let counter = 0;
            (taskInfo.submissions || []).forEach(submission => {
                counter++;
                submissions.push(Object.assign(submission, {
                    taskName: taskInfo.task.name,
                    taskScore: taskInfo.task.points,
                    firstOfKind: (counter == 1)
                }));
            });
        });

        return submissions;
    }


    renderPage() {

        return (
            <div className="text-left">
                <h2 className="mb-4">{this.props.event.name}</h2>


                <table className="table table-striped table-sm mb-4">
                    <thead>
                    <tr>
                        <th>Task</th>
                        <th>Time</th>
                        <th>Language</th>
                        <th>Score</th>
                    </tr>
                    </thead>

                    <tbody>
                    {this.allSubmissions().map((submission, index) => (

                        <React.Fragment key={submission.id}>
                            {(index > 0 && submission.firstOfKind)
                                ? (<tr>
                                    <td colSpan={4} style={{borderBottom: 0, backgroundColor: 'white'}}>&nbsp;</td>
                                </tr>)
                                : ''}

                            {(index > 0 && submission.firstOfKind)
                                ? (<tr>
                                    <td colSpan={4} style={{borderTop: 0, backgroundColor: 'white'}}>&nbsp;</td>
                                </tr>)
                                : ''}

                            <tr>
                                <td>{submission.taskName} {submission.analysisMode ? (
                                    <span className="badge badge-primary ml-2"
                                          title="Analysis mode">A</span>) : ''}</td>
                                <td>{submission.submittedOnStandard}</td>
                                <td>{submission.language}</td>
                                <td>
                                    {submission.score !== null
                                        ? (<span className="badge badge-danger" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/student/events/${this.props.event.id}/submission/${submission.id}`)}>{submission.score}</span>)
                                        : <div className="spinner-grow spinner-grow-sm text-danger"
                                               style={{marginRight: '7px', cursor: 'pointer'}} onClick={() => this.props.history.push(`/student/events/${this.props.event.id}/submission/${submission.id}`)}>
                                            <span className="sr-only">Loading...</span>
                                        </div>}
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}

                    {(this.allSubmissions().length === 0)
                        ? (<tr>
                            <td className="pt-3 pb-3 text-center font-weight-bold" colSpan={4}>No submissions found.
                            </td>
                        </tr>)
                        : ''}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        event: state.studentEvents.event,
        isLoadingEvent: state.studentEvents.isLoadingEvent,
        failedLoadingEvent: state.studentEvents.failedLoadingEvent,

        submissions: state.studentSubmissions.submissions,
        isLoadingSubmissions: state.studentSubmissions.isLoadingSubmissions,
        failedLoadingSubmissions: state.studentSubmissions.failedLoadingSubmissions,

        error: state.studentEvents.lastError
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadEvent: (eventId) => {
            dispatch(loadEvent(eventId));
        },
        standardEventUpdates: (eventId) => {
            dispatch(standardEventUpdates(eventId));
        },
        loadSubmissions: () => {
            dispatch(loadSubmissions());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentEventSubmissionsPage);
