import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import './styles.css';
import request from '../../lib/request';

Modal.setAppElement('#root');


class StudentQuestion extends React.Component {
    state = {
        question: '',
        sending: false,
        internalError: null
    };


    sendQuestion() {

        if (!this.state.question) {
            this.setState({internalError: 'Please write your question in the box below...'});
            return /* error */;
        }


        if (this.state.internalError) {
            this.setState({internalError: null, sending: true});
        }


        let eventId = this.props.eventId;
        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[eventId];

        return request.post('/student/events/' + eventId + '/question', {question: this.state.question}, {
            headers: {
                Authentication: authenticationToken,
                'Event-Token': eventToken
            }
        })
            .then(() => {

                this.setState({internalError: null, sending: false, question: ''}, () => {
                    this.props.onClose();
                });
            })
            .catch(() => {
                this.setState({
                    internalError: 'Ups, an error occurred while sending your question. Please fill the question box and try again...',
                    sending: false
                });
            });

    }


    render() {

        return (
            <Modal isOpen={this.props.open} onRequestClose={() => this.props.onClose()} className="question_modal_style"
                   overlayClassName="question_modal_overlay">
                <div style={{opacity: (this.state.sending ? 0.3 : 1)}}>
                    <h2>Ask a question</h2>
                    <p>Write your detailed question below...</p>

                    {(this.state.internalError) ? (
                        <p style={{
                            color: 'red',
                            margin: '30px 40px',
                            fontWeight: 'bold'
                        }}>{this.state.internalError}</p>
                    ) : ''}

                    <form>
                        <div className="form-group">
                            <label htmlFor="question">Question:</label>
                            <textarea className="form-control" id="question" name="question" value={this.state.question}
                                      onChange={(event) => this.setState({question: event.target.value})}
                                      rows={7}></textarea>
                        </div>
                    </form>

                    <div className="text-right" style={{marginTop: '20px'}}>
                        <button onClick={(event) => {
                            event.preventDefault();
                            this.sendQuestion();
                        }} className="btn btn-primary">Send</button>
                        &nbsp;
                        <button onClick={(event) => {
                            event.preventDefault();
                            this.props.onClose();
                        }} className="btn btn-danger">Close</button>
                    </div>
                </div>
            </Modal>
        );
    }
}


StudentQuestion.propTypes = {
    eventId: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    open: PropTypes.bool,

    onClose: PropTypes.func
};

export default StudentQuestion;
