import request from '../lib/request.js';
import {LOCATION_CHANGE, push} from 'connected-react-router';
import {CLEAR_SUBMISSION_DATA, loadSubmissions} from "./student-submissions";
import {logoutUser} from "./auth";

export const LOAD_EVENTS_STARTED = 'studentEvents/LOAD_EVENTS_STARTED';
export const LOAD_EVENTS_FAILED = 'studentEvents/LOAD_EVENTS_FAILED';
export const LOAD_EVENTS_FINISHED = 'studentEvents/LOAD_EVENTS_FINISHED';

export const LOAD_EVENT_STARTED = 'studentEvents/LOAD_EVENT_STARTED';
export const LOAD_EVENT_FAILED = 'studentEvents/LOAD_EVENT_FAILED';
export const LOAD_EVENT_FINISHED = 'studentEvents/LOAD_EVENT_FINISHED';

export const CHECK_EVENT_CODE_STARTED = 'studentEvents/CHECK_EVENT_CODE_STARTED';
export const CHECK_EVENT_CODE_FAILED = 'studentEvents/CHECK_EVENT_CODE_FAILED';
export const CHECK_EVENT_CODE_FINISHED = 'studentEvents/CHECK_EVENT_CODE_FINISHED';

export const LOAD_QUESTIONS_STARTED = 'studentEvents/LOAD_QUESTIONS_STARTED';
export const LOAD_QUESTIONS_FAILED = 'studentEvents/LOAD_QUESTIONS_FAILED';
export const LOAD_QUESTIONS_FINISHED = 'studentEvents/LOAD_QUESTIONS_FINISHED';

export const LOAD_RESULTS_STARTED = 'studentEvents/LOAD_RESULTS_STARTED';
export const LOAD_RESULTS_FAILED = 'studentEvents/LOAD_RESULTS_FAILED';
export const LOAD_RESULTS_FINISHED = 'studentEvents/LOAD_RESULTS_FINISHED';


const initialState = {
    event: null,
    eventId: null,

    events: null,
    questions: null,
    results: null,

    isLoadingEvents: false,
    failedLoadingEvents: false,

    isLoadingEvent: false,
    failedLoadingEvent: false,

    isLoadingQuestions: false,
    failedLoadingQuestions: false,

    isLoadingResults: false,
    failedLoadingResults: false,

    isCheckingEventCode: false,
    failedCheckingEventCode: false,

    lastError: null
};

export default (state = initialState, action) => {
    switch (action.type) {

        case LOCATION_CHANGE:
            return {
                ...state,
                failedLoadingEvents: false,
                failedLoadingEvent: false,
                failedLoadingQuestions: false,
                failedLoadingResults: false
            };

        case LOAD_EVENTS_STARTED:
            return {
                ...state,
                lastError: null,
                events: null,
                isLoadingEvents: true,
                failedLoadingEvents: false
            };


        case LOAD_EVENTS_FAILED:
            return {
                ...state,
                lastError: null,
                events: null,
                isLoadingEvents: false,
                failedLoadingEvents: new Date()
            };

        case LOAD_EVENTS_FINISHED:
            return {
                ...state,
                lastError: null,
                events: action.events,
                isLoadingEvents: false,
                failedLoadingEvents: false
            };


        case LOAD_EVENT_STARTED:
            return {
                ...state,
                lastError: null,
                eventId: action.eventId,
                event: null,
                questions: null,
                results: null,
                isLoadingEvent: true,
                failedLoadingEvent: false
            };


        case LOAD_EVENT_FAILED:
            return {
                ...state,
                lastError: null,
                event: null,
                questions: null,
                results: null,
                isLoadingEvent: false,
                failedLoadingEvent: new Date()
            };

        case LOAD_EVENT_FINISHED:
            return {
                ...state,
                lastError: null,
                event: ((action.event) ? (Object.assign({
                    receivingDate: ((new Date()).getTime())
                }, action.event)) : action.event),

                isLoadingEvent: false,
                failedLoadingEvent: false
            };


        case LOAD_QUESTIONS_STARTED:
            return {
                ...state,
                questions: null,
                isLoadingQuestions: true,
                failedLoadingQuestions: false
            };


        case LOAD_QUESTIONS_FAILED:
            return {
                ...state,
                questions: null,
                isLoadingQuestions: false,
                failedLoadingQuestions: new Date()
            };

        case LOAD_QUESTIONS_FINISHED:
            return {
                ...state,
                questions: action.questions,
                isLoadingQuestions: false,
                failedLoadingQuestions: false
            };


        case LOAD_RESULTS_STARTED:
            return {
                ...state,
                results: null,
                isLoadingResults: true,
                failedLoadingResults: false
            };


        case LOAD_RESULTS_FAILED:
            return {
                ...state,
                results: null,
                isLoadingResults: false,
                failedLoadingResults: new Date()
            };

        case LOAD_RESULTS_FINISHED:
            return {
                ...state,
                results: action.results,
                isLoadingResults: false,
                failedLoadingResults: false
            };


        case CHECK_EVENT_CODE_STARTED:
            return {
                ...state,
                lastError: null,
                isCheckingEventCode: true,
                failedCheckingEventCode: false
            };


        case CHECK_EVENT_CODE_FAILED:
            return {
                ...state,
                lastError: action.error || 'Invalid event code. Please try again.',
                isCheckingEventCode: false,
                failedCheckingEventCode: new Date()
            };

        case CHECK_EVENT_CODE_FINISHED:
            return {
                ...state,
                lastError: null,
                isCheckingEventCode: false,
                failedCheckingEventCode: false
            };

        default:
            return state
    }
}


export const loadEvents = () => {

    return dispatch => {
        dispatch({
            type: LOAD_EVENTS_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.get('/student/events', {headers: {Authentication: authenticationToken}})
            .then(({data}) => {


                dispatch({
                    type: LOAD_EVENTS_FINISHED,
                    events: data
                });

            })
            .catch((error) => {
                dispatch({
                    type: LOAD_EVENTS_FAILED
                });


                if (authenticationToken) {
                    if (error && error.response && error.response.status == '403' && error.response.data && error.response.data.message === 'Unauthorized.') {
                        if (window.location && window.location.href && window.location.href.indexOf('/student/events') >= 0) {
                            dispatch(logoutUser());
                        }
                    }
                }
            });
    }
};


export const loadEvent = (eventId, skipClear) => {

    return (dispatch, getState) => {
        if (!skipClear) {

            if (getState().studentEvents && getState().studentEvents.event && getState().studentEvents.event.id != eventId) {
                dispatch({
                    type: CLEAR_SUBMISSION_DATA
                });
            }

            dispatch({
                type: LOAD_EVENT_STARTED,
                eventId: eventId
            });
        }

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[eventId];

        return request.get('/student/events/' + eventId, {
            headers: {
                Authentication: authenticationToken,
                'Event-Token': eventToken
            }
        })
            .then(({data}) => {


                dispatch({
                    type: LOAD_EVENT_FINISHED,
                    event: data
                });

            })
            .catch((error) => {

                if (error && error.response && error.response.data && error.response.data.format) {
                    dispatch({
                        type: LOAD_EVENT_FINISHED,
                        event: error.response.data
                    });
                } else {

                    if (!skipClear) {
                        dispatch({
                            type: LOAD_EVENT_FAILED
                        });
                    }
                }
            });
    }
};


export const standardEventUpdates = (eventId) => {

    return (dispatch, getState) => {

        if (getState().studentEvents.event && getState().studentEvents.event.id == eventId) {

            let timeSinceLastUpdate = ((new Date()).getTime()) - (getState().studentEvents.event.receivingDate || 0);

            if (timeSinceLastUpdate >= 15 * 1000) {
                dispatch(loadEvent(eventId, true));

                //we're not currently looking at questions
                if (window && window.location && window.location.href && window.location.href.indexOf('/questions') < 0) {
                    dispatch(loadQuestions(true));
                }

                //if (window && window.location && window.location.href && window.location.href.indexOf('/results') < 0) {
                if (getState().studentEvents.results) {
                    dispatch(loadResults(true));
                }
                //}

                if (getState().studentSubmissions.submissions) {
                    dispatch(loadSubmissions(true));
                }
            }
        }
    }
};


export const checkEventCode = (eventId, code) => {

    return dispatch => {
        dispatch({
            type: CHECK_EVENT_CODE_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.post('/student/events/' + eventId + '/join', {code: code}, {headers: {Authentication: authenticationToken,}})
            .then(({data}) => {


                dispatch({
                    type: CHECK_EVENT_CODE_FINISHED
                });


                if (data && data.token) {
                    let eventTokens = JSON.parse(localStorage.getItem('App-Event-Token') || '{}');
                    eventTokens[eventId] = data.token;

                    localStorage.setItem('App-Event-Token', JSON.stringify(eventTokens));
                    dispatch(loadEvent(eventId));
                }


            })
            .catch((error) => {

                dispatch({
                    type: CHECK_EVENT_CODE_FAILED
                });
            });
    }
};


export const submitSolution = (eventId, taskId, data) => {

    return dispatch => {


        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[eventId];

        return request.post('/student/events/' + eventId + '/task/' + taskId + '/submission', data, {
            headers: {
                Authentication: authenticationToken,
                'Event-Token': eventToken
            }
        })
            .then(({data}) => {

                dispatch(push('/student/events/' + eventId + '/submission/' + data.id));
            })
            .catch((error) => {
            });
    }
};


export const loadQuestions = (skipClear) => {

    return (dispatch, getState) => {
        let event = getState().studentEvents.event;

        if (event && event.id) {
            if (!skipClear) {
                dispatch({
                    type: LOAD_QUESTIONS_STARTED
                });
            }

            let authenticationToken = localStorage.getItem('App-Authentication-Token');
            let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[event.id];

            return request.get('/student/events/' + event.id + '/questions', {
                headers: {
                    Authentication: authenticationToken,
                    'Event-Token': eventToken
                }
            })
                .then(({data}) => {


                    dispatch({
                        type: LOAD_QUESTIONS_FINISHED,
                        questions: data
                    });

                })
                .catch((error) => {
                    if (!skipClear) {
                        dispatch({
                            type: LOAD_QUESTIONS_FAILED
                        });
                    }
                });

        }


    }
};


export const loadResults = (skipClear) => {

    return (dispatch, getState) => {
        let event = getState().studentEvents.event;

        if (event && event.id) {

            if (!skipClear) {
                dispatch({
                    type: LOAD_RESULTS_STARTED
                });
            }

            let authenticationToken = localStorage.getItem('App-Authentication-Token');
            let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[event.id];

            return request.get('/student/events/' + event.id + '/results', {
                headers: {
                    Authentication: authenticationToken,
                    'Event-Token': eventToken
                }
            })
                .then(({data}) => {


                    dispatch({
                        type: LOAD_RESULTS_FINISHED,
                        results: data
                    });

                })
                .catch((error) => {

                    if (!skipClear) {
                        dispatch({
                            type: LOAD_RESULTS_FAILED
                        });
                    }
                });

        }


    }
};