import request from '../lib/request.js';
import {LOCATION_CHANGE, push} from 'connected-react-router';

export const LOAD_TASK_STARTED = 'teacherTasks/LOAD_TASK_STARTED';
export const LOAD_TASK_FAILED = 'teacherTasks/LOAD_TASK_FAILED';
export const LOAD_TASK_FINISHED = 'teacherTasks/LOAD_TASK_FINISHED';

export const LOAD_TASKS_STARTED = 'teacherTasks/LOAD_TASKS_STARTED';
export const LOAD_TASKS_FAILED = 'teacherTasks/LOAD_TASKS_FAILED';
export const LOAD_TASKS_FINISHED = 'teacherTasks/LOAD_TASKS_FINISHED';

export const CREATE_TASK_STARTED = 'teacherTasks/CREATE_TASK_STARTED';
export const CREATE_TASK_FAILED = 'teacherTasks/CREATE_TASK_FAILED';
export const CREATE_TASK_FINISHED = 'teacherTasks/CREATE_TASK_FINISHED';

export const EDIT_TASK_STARTED = 'teacherTasks/EDIT_TASK_STARTED';
export const EDIT_TASK_FAILED = 'teacherTasks/EDIT_TASK_FAILED';
export const EDIT_TASK_FINISHED = 'teacherTasks/EDIT_TASK_FINISHED';


const initialState = {
    task: null,
    tasks: null,

    isLoadingTasks: false,
    failedLoadingTasks: false,

    isLoadingTask: false,
    failedLoadingTask: false,

    isUpdatingTask: false,
    failedUpdatingTask: false,

    lastError: null
};

export default (state = initialState, action) => {
    switch (action.type) {

        case LOCATION_CHANGE:
            return {
                ...state,
                failedLoadingTasks: false,
                failedLoadingTask: false
            };

        case LOAD_TASKS_STARTED:
            return {
                ...state,
                lastError: null,
                tasks: null,
                isLoadingTasks: true,
                failedLoadingTasks: false
            };

        case LOAD_TASK_STARTED:
            return {
                ...state,
                lastError: null,
                task: null,
                isLoadingTask: true,
                failedLoadingTask: false
            };

        case LOAD_TASKS_FAILED:
            return {
                ...state,
                tasks: null,
                lastError: action.error || null,
                isLoadingTasks: false,
                failedLoadingTasks: new Date()
            };

        case LOAD_TASK_FAILED:
            return {
                ...state,
                task: null,
                lastError: action.error || null,
                isLoadingTask: false,
                failedLoadingTask: new Date()
            };

        case LOAD_TASKS_FINISHED:
            return {
                ...state,
                tasks: action.tasks,
                lastError: null,
                isLoadingTasks: false,
                failedLoadingTasks: false
            };


        case LOAD_TASK_FINISHED:
            return {
                ...state,
                lastError: null,
                task: action.task,
                isLoadingTask: false,
                failedLoadingTask: false
            };


        case CREATE_TASK_STARTED:
        case EDIT_TASK_STARTED:
            return {
                ...state,
                lastError: null,
                isUpdatingTask: true,
                failedUpdatingTask: false
            };

        case CREATE_TASK_FAILED:
        case EDIT_TASK_FAILED:
            return {
                ...state,
                lastError: action.error || null,
                isUpdatingTask: false,
                failedUpdatingTask: new Date()
            };

        case CREATE_TASK_FINISHED:
            return {
                ...state,
                lastError: null,
                tasks: [...state.tasks, action.task],
                isUpdatingTask: false,
                failedUpdatingTask: false
            };

        case EDIT_TASK_FINISHED:
            return {
                ...state,
                lastError: null,
                task: null,
                tasks: state.tasks.slice().map(current => {
                    if (current.id === action.task.id) {
                        return action.task;
                    } else {
                        return current;
                    }
                }),

                isUpdatingTask: false,
                failedUpdatingTask: false
            };

        default:
            return state
    }
}


export const loadTasks = () => {

    return dispatch => {
        dispatch({
            type: LOAD_TASKS_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.get('/teacher/tasks', {headers: {Authentication: authenticationToken}})
            .then(({data}) => {


                dispatch({
                    type: LOAD_TASKS_FINISHED,
                    tasks: data
                });

            })
            .catch((error) => {
                dispatch({
                    type: LOAD_TASKS_FAILED
                });
            });
    }
};


export const loadTask = (taskId, forceRefresh) => {

    return (dispatch, getState) => {

        if (forceRefresh || (!getState().teacherTasks.task || getState().teacherTasks.task.id != taskId)) {

            dispatch({
                type: LOAD_TASK_STARTED
            });
        }

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.get('/teacher/tasks/' + taskId, {headers: {Authentication: authenticationToken}})
            .then(({data}) => {

                dispatch({
                    type: LOAD_TASK_FINISHED,
                    task: data
                });

            })
            .catch((error) => {
                dispatch({
                    type: LOAD_TASK_FAILED
                });
            });
    }
};


export const createTask = (settings) => {

    return dispatch => {
        dispatch({
            type: CREATE_TASK_STARTED
        });

        let settingsFormData = getFormData(settings);
        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.post('/teacher/tasks/add', settingsFormData, {
            headers: {
                Authentication: authenticationToken,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {

                dispatch({
                    type: CREATE_TASK_FINISHED,
                    task: data
                });

                if (settings.tests) {
                    dispatch(push('/teacher/tasks/edit/' + data.id + '?parsing=true'));
                } else {
                    dispatch(push('/teacher/tasks'));
                }
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_TASK_FAILED,
                    error: (error.response && error.response.data ? error.response.data.message : error) || error
                });
            });
    }

};

export const updateTask = (taskId, settings) => {

    return dispatch => {
        dispatch({
            type: EDIT_TASK_STARTED
        });

        let settingsFormData = getFormData(settings);
        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.post('/teacher/tasks/edit/' + taskId, settingsFormData, {
            headers: {
                Authentication: authenticationToken,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {

                dispatch({
                    type: EDIT_TASK_FINISHED,
                    task: data
                });

                if (settings.tests) {
                    dispatch(push('/teacher/tasks/edit/' + data.id + '?parsing=true'));
                } else {
                    dispatch(push('/teacher/tasks'));
                }
            })
            .catch((error) => {
                dispatch({
                    type: EDIT_TASK_FAILED,
                    error: (error.response && error.response.data ? error.response.data.message : error) || error
                });
            });
    }
};


function getFormData(settings) {
    let data = new FormData();

    for (let key in settings) {
        if (key === 'tests' || key === 'solutions') {

            //a file
            if (settings[key]) {
                if (key === 'tests') {
                    data.append('data', settings[key]);
                }

                if (key === 'solutions') {

                    var numFiles = settings[key].length;
                    for (let x = 0; x < numFiles; x++) {
                        data.append("solutions[]", settings[key][x]);
                    }
                }
            }

        } else {
            if (settings[key]) {
                data.set(key, settings[key]);
            }
        }
    }


    return data;
}