import React from 'react';
import {Link} from 'react-router-dom';
import config from '../../config/default.json';

import {connect} from 'react-redux';
import {checkEventCode, loadEvent, standardEventUpdates} from '../../store/student-events';

import "./styles.css";
import LoadingSpinner from "../../components/LoadingSpinner";
import StudentEventSidebar from "../../components/StudentEventSidebar";

class StudentEventPage extends React.Component {

    state = {
        code: '',
        internalError: undefined
    };

    componentWillMount() {
        let eventId = this.props.match.params.id;

        if ((!this.props.event || this.props.event.id != eventId) && !this.props.isLoadingEvent) {
            if (this.props.event) {
                this.props.loadEvent(eventId);
            } else {
                setTimeout(() => {
                    this.props.loadEvent(eventId);
                }, 1000);
            }
        }
    }

    componentWillReceiveProps(newProps) {
        let eventId = newProps.match.params.id;

        if (newProps.event && newProps.event.id != eventId && !newProps.isLoadingEvent) {
            this.props.loadEvent(eventId);
        }
    }


    componentDidMount() {
        let eventId = this.props.match.params.id;

        if (this.props.event && this.props.event.id == eventId) {
            this.props.standardEventUpdates(eventId);
        }

        this.intervalId = setInterval(() => {
            if (this.props.event && this.props.event.id == eventId) {
                this.props.standardEventUpdates(eventId);
            }
        }, 40 * 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }


    encodeTime(minutes) {

        if (minutes > 24 * 60) {
            return Math.floor(minutes / (24 * 60)) + ' day(s)';
        }

        if (minutes > 60) {
            return Math.floor(minutes / 60) + ' hour(s)';
        } else {
            return Math.max(1, Math.round(minutes)) + ' minute(s)';
        }
    }


    submitForm() {

        let eventId = this.props.match.params.id;

        this.setState({internalError: null}, () => {
            if (!this.state.code) {
                this.setState({internalError: 'Please enter the event code and try again!'});
                return /* don't submit form */;
            }

            this.props.checkEventCode(eventId, this.state.code);
        });
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col text-center">


                        {(!this.props.event) ? (
                            <div>
                                <h2 className="mb-4">{config.APP_NAME}</h2>
                                <LoadingSpinner/>
                            </div>) : ''}


                        {(this.props.event && this.props.event.format && this.props.event.status === 'waiting') ? (
                            <div>
                                <h2 className="mb-1">{this.props.event.name}</h2>
                                <p className="mb-5">Please wait until the event starts.</p>

                                <p>Expected time:<br/>
                                    <strong style={{color: 'red', fontSize: '1.5rem'}}>Starts
                                        in {this.encodeTime(this.props.event.startsInMinutes)}</strong>
                                </p>
                            </div>
                        ) : ''}

                        {(this.props.event && this.props.event.format && this.props.event.status === 'blocked') ? (
                            <div>
                                <h2 className="mb-a">{this.props.event.name}</h2>
                                <p className="mb-5">This event requires code authorization.</p>


                                <form className="form text-center code-authorization-form mt-4"
                                      style={{opacity: this.props.isCheckingEventCode ? 0.3 : 1}} onSubmit={(event) => {
                                    event.preventDefault();
                                    this.submitForm();
                                }}>

                                    {(this.state.internalError || this.props.failedCheckingEventCode) ?
                                        <p className="form-error">{this.state.internalError || this.props.error || 'Failed to confirm event code. Please try again.'}</p> : ''}

                                    <div className="form-group col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                        <label htmlFor="name">&nbsp;</label>
                                        <input type="text" className="form-control text-center" id="code" name="code"
                                               autoFocus
                                               placeholder="Enter the event code" value={this.state.code}
                                               onChange={event => this.setState({code: event.target.value})}/>
                                    </div>

                                    <button className="btn btn-primary" onClick={(event) => {
                                        event.preventDefault();
                                        this.submitForm();
                                    }}>Authenticate
                                    </button>
                                </form>

                            </div>
                        ) : ''}


                        {(this.props.event && !this.props.event.format && this.props.event.status)
                            ? (
                                <div className="row text-left">
                                    <div className="col-12 col-md-4 col-lg-3">
                                        <StudentEventSidebar event={this.props.event}
                                                             pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}/>
                                    </div>

                                    <div className="col-12 col-md-8 col-lg-9">

                                        <h3>{this.props.event.name}</h3>
                                        <p>Click on an item below (an article, or a task) to read more about it, submit
                                            a solution, or view any additional options or contents associated with it.
                                            Good luck!</p>

                                        <table className="table table-event-items table-striped mt-5 mb-5">
                                            <thead>
                                            <tr>
                                                <th className="text-right">Type</th>
                                                <th>Name</th>
                                                <th>Points</th>
                                                <th>Time limit</th>
                                                <th>Memory limit</th>
                                            </tr>
                                            </thead>


                                            <tbody>

                                            {(this.props.event.articles.map(article => (
                                                <tr key={article.id}>
                                                    <td className="text-right"><span
                                                        className="badge badge-success">A</span></td>
                                                    <td><Link
                                                        to={`/student/events/${this.props.event.id}/article/${article.id}`}>{article.name}</Link>
                                                    </td>
                                                    <td>N/A</td>
                                                    <td>N/A</td>
                                                    <td>N/A</td>
                                                </tr>
                                            )))}

                                            {(this.props.event.articles.length > 0
                                                ? (<tr style={{backgroundColor: '#fff'}}>
                                                    <td colspan="5">&nbsp;</td>
                                                </tr>)
                                                : '')}


                                            {(this.props.event.tasks.map(task => (
                                                <tr key={task.id}>
                                                    <td className="text-right"><span
                                                        className="badge badge-primary">T</span></td>
                                                    <td><Link
                                                        to={`/student/events/${this.props.event.id}/task/${task.id}`}>{task.name}</Link>
                                                    </td>
                                                    <td>{task.points}</td>
                                                    <td>{task.timeLimit} ms</td>
                                                    <td>{Math.ceil(task.memoryLimit / 1024)} MB</td>
                                                </tr>
                                            )))}
                                            </tbody>
                                        </table>


                                        <h4 className="mt-6 pt-3">More information</h4>
                                        <p className="mb-0 mt-2">For any additional questions or issues, please feel
                                            free to contact the event organizers.</p>
                                        <p>This event is organized with the <strong>{config.APP_NAME}</strong> system.
                                        </p>
                                    </div>
                                </div>

                            ) : ''}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        event: state.studentEvents.event,
        isLoadingEvent: state.studentEvents.isLoadingEvent,
        failedLoadingEvent: state.studentEvents.failedLoadingEvent,

        error: state.studentEvents.lastError,
        isCheckingEventCode: state.studentEvents.isCheckingEventCode,
        failedCheckingEventCode: state.studentEvents.failedCheckingEventCode
    }
};

function mapDispatchToProps(dispatch) {
    return {
        checkEventCode: (eventId, code) => {
            dispatch(checkEventCode(eventId, code));
        },
        loadEvent: (eventId) => {
            dispatch(loadEvent(eventId));
        },
        standardEventUpdates: (eventId) => {
            dispatch(standardEventUpdates(eventId));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentEventPage);
