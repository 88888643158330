import request from '../lib/request.js';
import {LOCATION_CHANGE, push} from 'connected-react-router';

export const LOAD_ARTICLE_STARTED = 'teacherArticles/LOAD_ARTICLE_STARTED';
export const LOAD_ARTICLE_FAILED = 'teacherArticles/LOAD_ARTICLE_FAILED';
export const LOAD_ARTICLE_FINISHED = 'teacherArticles/LOAD_ARTICLE_FINISHED';

export const LOAD_ARTICLES_STARTED = 'teacherArticles/LOAD_ARTICLES_STARTED';
export const LOAD_ARTICLES_FAILED = 'teacherArticles/LOAD_ARTICLES_FAILED';
export const LOAD_ARTICLES_FINISHED = 'teacherArticles/LOAD_ARTICLES_FINISHED';

export const CREATE_ARTICLE_STARTED = 'teacherArticles/CREATE_ARTICLE_STARTED';
export const CREATE_ARTICLE_FAILED = 'teacherArticles/CREATE_ARTICLE_FAILED';
export const CREATE_ARTICLE_FINISHED = 'teacherArticles/CREATE_ARTICLE_FINISHED';

export const EDIT_ARTICLE_STARTED = 'teacherArticles/EDIT_ARTICLE_STARTED';
export const EDIT_ARTICLE_FAILED = 'teacherArticles/EDIT_ARTICLE_FAILED';
export const EDIT_ARTICLE_FINISHED = 'teacherArticles/EDIT_ARTICLE_FINISHED';


const initialState = {
    article: null,
    articles: null,

    isLoadingArticles: false,
    failedLoadingArticles: false,

    isLoadingArticle: false,
    failedLoadingArticle: false,

    isUpdatingArticle: false,
    failedUpdatingArticle: false,

    lastError: null
};

export default (state = initialState, action) => {
    switch (action.type) {

        case LOCATION_CHANGE:
            return {
                ...state,
                failedLoadingArticles: false,
                failedLoadingArticle: false
            };

        case LOAD_ARTICLES_STARTED:
            return {
                ...state,
                lastError: null,
                articles: null,
                isLoadingArticles: true,
                failedLoadingArticles: false
            };

        case LOAD_ARTICLE_STARTED:
            return {
                ...state,
                lastError: null,
                article: null,
                isLoadingArticle: true,
                failedLoadingArticle: false
            };

        case LOAD_ARTICLES_FAILED:
            return {
                ...state,
                articles: null,
                lastError: action.error || null,
                isLoadingArticles: false,
                failedLoadingArticles: new Date()
            };

        case LOAD_ARTICLE_FAILED:
            return {
                ...state,
                article: null,
                lastError: action.error || null,
                isLoadingArticle: false,
                failedLoadingArticle: new Date()
            };

        case LOAD_ARTICLES_FINISHED:
            return {
                ...state,
                articles: action.articles,
                lastError: null,
                isLoadingArticles: false,
                failedLoadingArticles: false
            };


        case LOAD_ARTICLE_FINISHED:
            return {
                ...state,
                lastError: null,
                article: action.article,
                isLoadingArticle: false,
                failedLoadingArticle: false
            };


        case CREATE_ARTICLE_STARTED:
        case EDIT_ARTICLE_STARTED:
            return {
                ...state,
                lastError: null,
                isUpdatingArticle: true,
                failedUpdatingArticle: false
            };

        case CREATE_ARTICLE_FAILED:
        case EDIT_ARTICLE_FAILED:
            return {
                ...state,
                lastError: action.error || null,
                isUpdatingArticle: false,
                failedUpdatingArticle: new Date()
            };

        case CREATE_ARTICLE_FINISHED:
            return {
                ...state,
                lastError: null,
                articles: [...state.articles, action.article],
                isUpdatingArticle: false,
                failedUpdatingArticle: false
            };

        case EDIT_ARTICLE_FINISHED:
            return {
                ...state,
                lastError: null,
                articles: state.articles.slice().map(current => {
                    if (current.id === action.article.id) {
                        return action.article;
                    } else {
                        return current;
                    }
                }),

                isUpdatingArticle: false,
                failedUpdatingArticle: false
            };

        default:
            return state
    }
}


export const loadArticles = () => {

    return dispatch => {
        dispatch({
            type: LOAD_ARTICLES_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.get('/teacher/articles', {headers: {Authentication: authenticationToken}})
            .then(({data}) => {


                dispatch({
                    type: LOAD_ARTICLES_FINISHED,
                    articles: data
                });

            })
            .catch((error) => {
                dispatch({
                    type: LOAD_ARTICLES_FAILED
                });
            });
    }
};


export const loadArticle = (articleId) => {

    return dispatch => {
        dispatch({
            type: LOAD_ARTICLE_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.get('/teacher/articles/' + articleId, {headers: {Authentication: authenticationToken}})
            .then(({data}) => {


                dispatch({
                    type: LOAD_ARTICLE_FINISHED,
                    article: data
                });

            })
            .catch((error) => {
                dispatch({
                    type: LOAD_ARTICLE_FAILED
                });
            });
    }
};


export const createArticle = (title, contents, privateItem, approved) => {

    return dispatch => {
        dispatch({
            type: CREATE_ARTICLE_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.post('/teacher/articles/add', {
            name: title,
            contents,
            private: privateItem,
            approved
        }, {headers: {Authentication: authenticationToken}})
            .then(({data}) => {

                dispatch({
                    type: CREATE_ARTICLE_FINISHED,
                    article: data
                });

                dispatch(push('/teacher/articles'));
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_ARTICLE_FAILED,
                    error: (error.response && error.response.data ? error.response.data.message : error) || error
                });
            });
    }

};

export const updateArticle = (articleId, title, contents, privateItem, approved) => {

    return dispatch => {
        dispatch({
            type: EDIT_ARTICLE_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.post('/teacher/articles/edit/' + articleId, {
            name: title,
            contents,
            private: privateItem,
            approved
        }, {headers: {Authentication: authenticationToken}})
            .then(({data}) => {

                dispatch({
                    type: EDIT_ARTICLE_FINISHED,
                    article: data
                });

                dispatch(push('/teacher/articles'));
            })
            .catch((error) => {
                dispatch({
                    type: EDIT_ARTICLE_FAILED,
                    error: (error.response && error.response.data ? error.response.data.message : error) || error
                });
            });
    }
};
