import request from '../lib/request.js';
import {LOCATION_CHANGE, push} from 'connected-react-router';

export const LOAD_EVENTS_STARTED = 'teacherEvents/LOAD_EVENTS_STARTED';
export const LOAD_EVENTS_FAILED = 'teacherEvents/LOAD_EVENTS_FAILED';
export const LOAD_EVENTS_FINISHED = 'teacherEvents/LOAD_EVENTS_FINISHED';

export const CREATE_EVENT_STARTED = 'teacherEvents/CREATE_EVENT_STARTED';
export const CREATE_EVENT_FAILED = 'teacherEvents/CREATE_EVENT_FAILED';
export const CREATE_EVENT_FINISHED = 'teacherEvents/CREATE_EVENT_FINISHED';

export const EDIT_EVENT_STARTED = 'teacherEvents/EDIT_EVENT_STARTED';
export const EDIT_EVENT_FAILED = 'teacherEvents/EDIT_EVENT_FAILED';
export const EDIT_EVENT_FINISHED = 'teacherEvents/EDIT_EVENT_FINISHED';

export const LOAD_QUESTIONS_STARTED = 'teacherEvents/LOAD_QUESTIONS_STARTED';
export const LOAD_QUESTIONS_FAILED = 'teacherEvents/LOAD_QUESTIONS_FAILED';
export const LOAD_QUESTIONS_FINISHED = 'teacherEvents/LOAD_QUESTIONS_FINISHED';

export const QUESTION_ANSWER_FINISHED = 'teacherEvents/QUESTION_ANSWER_FINISHED';


const initialState = {
    events: null,
    scoringModes: null,

    questions: null,
    isLoadingQuestions: false,
    failedLoadingQuestions: false,

    isLoadingEvents: false,
    failedLoadingEvents: false,

    isUpdatingEvent: false,
    failedUpdatingEvent: false,

    lastError: null,
};

export default (state = initialState, action) => {
    switch (action.type) {

        case LOCATION_CHANGE:
            return {
                ...state,
                failedLoadingQuestions: false,
                failedLoadingEvents: false
            };

        case LOAD_EVENTS_STARTED:
            return {
                ...state,
                lastError: null,
                events: null,
                scoringModes: null,
                isLoadingEvents: true,
                failedLoadingEvents: false
            };


        case LOAD_EVENTS_FAILED:
            return {
                ...state,
                lastError: null,
                events: null,
                scoringModes: null,
                isLoadingEvents: false,
                failedLoadingEvents: new Date()
            };

        case LOAD_EVENTS_FINISHED:
            return {
                ...state,
                lastError: null,
                events: action.events,
                scoringModes: action.scoringModes,
                isLoadingEvents: false,
                failedLoadingEvents: false
            };


        case CREATE_EVENT_STARTED:
        case EDIT_EVENT_STARTED:
            return {
                ...state,
                lastError: null,
                isUpdatingEvent: true,
                failedUpdatingEvent: false
            };

        case CREATE_EVENT_FAILED:
        case EDIT_EVENT_FAILED:
            return {
                ...state,
                lastError: action.error || null,
                isUpdatingEvent: false,
                failedUpdatingEvent: new Date()
            };

        case CREATE_EVENT_FINISHED:
            return {
                ...state,
                lastError: null,
                events: [...state.events, action.event],
                isUpdatingEvent: false,
                failedUpdatingEvent: false
            };

        case EDIT_EVENT_FINISHED:
            return {
                ...state,
                lastError: null,
                events: state.events.slice().map(current => {
                    if (current.id === action.event.id) {
                        return action.event;
                    } else {
                        return current;
                    }
                }),

                isUpdatingEvent: false,
                failedUpdatingEvent: false
            };


        case LOAD_QUESTIONS_STARTED:
            return {
                ...state,
                lastError: null,
                isLoadingQuestions: true,
                failedLoadingQuestions: false
            };


        case LOAD_QUESTIONS_FAILED:
            return {
                ...state,
                lastError: null,
                isLoadingQuestions: false,
                failedLoadingQuestions: new Date()
            };

        case LOAD_QUESTIONS_FINISHED:
            return {
                ...state,
                lastError: null,
                questions: Object.assign((state.questions || []), {[action.eventId]: action.questions}),
                isLoadingQuestions: false,
                failedLoadingQuestions: false
            };


        case QUESTION_ANSWER_FINISHED:
            let questions = (state.questions || []).slice();
            if (questions[action.eventId]) {
                questions[action.eventId] = questions[action.eventId].map(q => {
                    if (q.id == action.questionId) {
                        return Object.assign(q, action.data);
                    } else {
                        return q;
                    }
                });
            }

            return {
                ...state,
                questions: questions
            };

        default:
            return state
    }
}


export const loadEvents = (skipClear) => {

    return dispatch => {
        if (!skipClear) {
            dispatch({
                type: LOAD_EVENTS_STARTED
            });
        }

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.get('/teacher/events', {headers: {Authentication: authenticationToken}})
            .then(({data}) => {


                dispatch({
                    type: LOAD_EVENTS_FINISHED,
                    events: data.events,
                    scoringModes: data.scoringModes,
                });

            })
            .catch((error) => {
                if (!skipClear) {
                    dispatch({
                        type: LOAD_EVENTS_FAILED
                    });
                }
            });
    }
};


export const createEvent = (settings) => {

    return dispatch => {
        dispatch({
            type: CREATE_EVENT_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.post('/teacher/events/add', settings, {headers: {Authentication: authenticationToken}})
            .then(({data}) => {

                dispatch({
                    type: CREATE_EVENT_FINISHED,
                    event: data
                });

                dispatch(push('/teacher/events'));
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_EVENT_FAILED,
                    error: (error.response && error.response.data ? error.response.data.message : error) || error
                });
            });
    }

};

export const updateEvent = (eventId, settings) => {

    return dispatch => {
        dispatch({
            type: EDIT_EVENT_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.post('/teacher/events/edit/' + eventId, settings, {headers: {Authentication: authenticationToken}})
            .then(({data}) => {

                dispatch({
                    type: EDIT_EVENT_FINISHED,
                    event: data
                });

                dispatch(push('/teacher/events'));
            })
            .catch((error) => {
                dispatch({
                    type: EDIT_EVENT_FAILED,
                    error: (error.response && error.response.data ? error.response.data.message : error) || error
                });
            });
    }
};


export const loadQuestions = (eventId, skipClear) => {

    return (dispatch, getState) => {
        if (!skipClear) {
            dispatch({
                type: LOAD_QUESTIONS_STARTED
            });
        }

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[eventId];

        return request.get('/teacher/events/' + eventId + '/questions', {
            headers: {
                Authentication: authenticationToken,
                'Event-Token': eventToken
            }
        })
            .then(({data}) => {


                dispatch({
                    type: LOAD_QUESTIONS_FINISHED,
                    eventId: eventId,
                    questions: data
                });

            })
            .catch((error) => {

                if (!skipClear) {
                    dispatch({
                        type: LOAD_QUESTIONS_FAILED
                    });
                }
            });


    }
};


export const sendAnswerToEventQuestion = (eventId, questionId, requestData) => {

    return dispatch => {

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[eventId];

        return request.post('/teacher/events/' + eventId + '/question/' + questionId, requestData, {
            headers: {
                Authentication: authenticationToken,
                'Event-Token': eventToken
            }
        })
            .then(({data}) => {

                dispatch({
                    type: QUESTION_ANSWER_FINISHED,
                    eventId: eventId,
                    questionId: questionId,

                    data: data
                });

            })
            .catch((error) => {
            });
    }
};