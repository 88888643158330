import React from 'react';

import request from '../../lib/request';

import {connect} from 'react-redux';
import LoadingSpinner from "../../components/LoadingSpinner";

import {loadEvents} from '../../store/teacher-events';
import TeacherSidebar from "../../components/TeacherSidebar";
import {Link} from "react-router-dom";


class TeacherEventResultsPage extends React.Component {
    state = {
        regrading: false,

        isLoadingResults: false,
        failedLoadingResults: false,

        event: null,
        eventId: null,
        results: null
    };

    componentWillMount() {

        if (!this.props.events && !this.props.isLoadingEvents) {
            this.props.loadEvents();
        }

        this.setupEvent(this.props);

        let newEventId = this.props.match.params.id;
        this.loadResults(newEventId);
    }

    setupEvent(nextProps) {
        let event = null;

        let newEventId = nextProps.match.params.id;
        if (nextProps.events && nextProps.events.find(e => (e.id == newEventId))) {
            event = nextProps.events.find(e => (e.id == newEventId));
        }

        if (event) {
            this.setState({
                event: event,
                eventId: newEventId
            });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps && nextProps.match && nextProps.match.params && nextProps.match.params.id) {
            let newEventId = nextProps.match.params.id;

            this.setupEvent(nextProps);

            if (!this.state.eventId || this.state.eventId != newEventId) {
                this.loadResults(newEventId);
            }
        }
    }

    loadResults(eventId) {

        if (!eventId) {
            return /* skip */;
        }


        this.setState({
            isLoadingResults: true,
            failedLoadingResults: false
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[eventId];

        return request.get('/teacher/events/' + eventId + '/results', {
            headers: {
                Authentication: authenticationToken,
                'Event-Token': eventToken
            }
        })
            .then(({data}) => {

                //simulate delay, for loading effect
                setTimeout(() => {

                    this.setState({
                        results: data,

                        isLoadingResults: false,
                        failedLoadingResults: false
                    });

                }, 100);
            })
            .catch((error) => {
                this.setState({
                    isLoadingResults: false,
                    failedLoadingResults: true
                })
            });
    }



    regrade() {

        let eventId = this.props.match.params.id;

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[eventId];

        if (!this.state.regrading) {
            return request.post('/teacher/events/' + eventId + '/regrade', {
                headers: {
                    Authentication: authenticationToken,
                    'Event-Token': eventToken
                }
            }).then(() => {
                this.setState({ regrading: true });
            });
        }
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <TeacherSidebar event={this.state.event}
                                        pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}
                                        administrator={this.props.accountType === 'admin'}/>
                    </div>

                    <div className="col-12 col-md-8 col-lg-9">
                        {(!this.state.event || !this.state.eventId || this.state.results === null) ?
                            <LoadingSpinner/> : (
                                <div>{this.renderPage()}</div>
                            )}
                    </div>
                </div>
            </div>
        );
    }


    renderPage() {

        let previousPoints = 1e100;
        let posCounter = 0;
        let participantsCounter = 0;

        return (
            <div className="text-left">
                <h2 className="mb-4">{this.state.event.name}</h2>


                <table className="table table-striped mt-2">

                    <thead>
                    <tr>
                        <th className="text-right">#</th>
                        <th>Username</th>
                        <th>Full name</th>

                        {this.state.event.tasks.map(task => (
                            <th key={task.id} className="text-center">{task.name}</th>
                        ))}

                        <th className="text-right">Total</th>
                    </tr>
                    </thead>


                    <tbody>

                    {(this.state.results.length === 0)
                        ? (
                            <tr>
                                <td colSpan={(4 + this.state.event.tasks.length)}
                                    className="pt-4 pb-5 font-weight-bold text-center" style={{fontSize: '1.5rem'}}>
                                    No submissions found.
                                </td>
                            </tr>
                        )
                        : ''}


                    {this.state.results.map(participant => {

                        participantsCounter++;

                        if (participant.total < previousPoints) {
                            previousPoints = participant.total;
                            posCounter = participantsCounter;
                        }

                        return (
                            <tr key={participant.username}>
                                <td className="text-right">{posCounter}.</td>
                                <td>{participant.username}</td>
                                <td>{participant.fullName}</td>

                                {this.state.event.tasks.map(task => (
                                    <td key={task.id}
                                        className="text-center">{(participant.tasks[task.id] !== undefined)
                                        ? <Link
                                            to={`/teacher/events/${this.state.event.id}/submissions/${participant.tasks[task.id].submissionId}`}
                                            style={{color: '#212529'}}>{participant.tasks[task.id].points}</Link>
                                        : '/'}</td>
                                ))}

                                <td className="text-right">{participant.total}</td>
                            </tr>
                        )

                    })}

                    </tbody>
                </table>



                <div className="clearfix mt-2 mb-4">
                    <button className={"btn btn-danger right" + (this.state.regrading ? ' disabled' : '')} onClick={() => { this.regrade(); }}>Regrade all</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        error: state.teacherEvents.lastError,
        events: state.teacherEvents.events,

        isLoadingEvents: state.teacherEvents.isLoadingEvents,
        failedLoadingEvents: state.teacherEvents.failedLoadingEvents,

        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadEvents: () => {
            dispatch(loadEvents());
        }

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherEventResultsPage);
