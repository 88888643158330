import React from 'react';
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import request from '../../lib/request';

import {connect} from 'react-redux';
import LoadingSpinner from "../../components/LoadingSpinner";

import TeacherSidebar from "../../components/TeacherSidebar";


class AdminGradersPage extends React.Component {
    state = {
        graders: null,

        isLoadingGraders: false,
        failedLoadingGraders: false
    };

    componentWillMount() {
        this.loadGraders();
    }

    componentWillReceiveProps(nextProps) {

        if (!this.state.graders && !this.state.isLoadingGraders) {
            this.loadGraders();
        }
    }

    loadGraders() {

        this.setState({
            graders: null,

            isLoadingGraders: true,
            failedLoadingGraders: false
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');

        return request.get('/admin/graders', {
            headers: {
                Authentication: authenticationToken
            }
        })
            .then(({data}) => {

                //simulate delay, for loading effect
                setTimeout(() => {

                    this.setState({
                        graders: data,

                        isLoadingGraders: false,
                        failedLoadingGraders: false
                    });

                }, 100);
            })
            .catch((error) => {
                this.setState({
                    isLoadingGraders: false,
                    failedLoadingGraders: true
                })
            });
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <TeacherSidebar event={this.state.event}
                                        pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}
                                        administrator={this.props.accountType === 'admin'}/>
                    </div>

                    <div className="col-12 col-md-8 col-lg-9">
                        {(!this.state.graders) ?
                            <LoadingSpinner/> : (
                                <div>{this.renderPage()}</div>
                            )}
                    </div>
                </div>
            </div>
        );
    }


    renderPage() {

        let posCounter = 0;

        return (
            <div className="text-left">

                {(this.state.graders.length === 0)
                    ? (
                        <div className="pt-5 pb-5 mt-5 mb-5 text-center">
                            <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}}>
                                <span className="sr-only">Loading...</span>
                            </div>

                            <p className="font-weight-bold mt-3 pt-3 text-center">Waiting for a grader...</p>
                        </div>
                    )
                    : ''}

                {this.state.graders.map(grader => {

                    let lastStats = grader.stats[grader.stats.length - 1].stats;

                    let loadData = grader.stats.map((s, index) => ({
                        time: (index + 1),
                        load: Math.round(s.stats.load.currentload)
                    }));


                    let diskUsageData = grader.stats.map((s, index) => ({
                        time: (index + 1),
                        load: Math.round(s.stats.disks.length > 0 ? s.stats.disks[0].use : 10)
                    }));

                    return (
                        <div key={grader.name} className="grader pb-3 mb-5">
                            <h3>{grader.name}</h3>
                            <p className="mb-0">CPU: {lastStats.cpu.manufacturer} {lastStats.cpu.brand} ({lastStats.cpu.cores} cores)</p>

                            <h4 className="font-weight-bold text-right"><span
                                className={"badge " + (grader.status == 'Online' ? 'badge-success' : 'badge-danger')}>{grader.status}</span>
                            </h4>


                            <h5 className="mt-4">System load</h5>
                            <ResponsiveContainer aspect={14.0 / 3.0} width='100%'>
                                <AreaChart
                                    data={loadData}
                                    margin={{
                                        top: 10, right: 30, left: 0, bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="time"/>
                                    <YAxis tickFormatter={(t) => (t + '%')} ticks={[20, 40, 60, 80, 100]}/>
                                    <Tooltip/>
                                    <Area type="monotone" dataKey="load" stroke="#82c99b" fill="#8fd8a8"/>
                                </AreaChart>
                            </ResponsiveContainer>


                            <h5 className="mt-5 pt-3">Disk Usage</h5>
                            <ResponsiveContainer aspect={12.0 / 3.0} width='100%'>
                                <AreaChart
                                    data={diskUsageData}
                                    margin={{
                                        top: 10, right: 30, left: 0, bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="time"/>
                                    <YAxis tickFormatter={(t) => (t + '%')} ticks={[20, 40, 60, 80, 100]}/>
                                    <Tooltip/>
                                    <Area type="monotone" dataKey="load" stroke="#b4a9ee" fill="#bcb5ed"/>
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>


                    )
                })}

            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminGradersPage);
