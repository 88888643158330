import React from 'react';
import config from '../../config/default.json';

import {connect} from 'react-redux';
import {loadAccounts} from '../../store/admin-accounts';

import LoadingSpinner from "../../components/LoadingSpinner";
import TeacherSidebar from "../../components/TeacherSidebar";


class AdminAccountsPage extends React.Component {

    state = {
        query: ''
    };

    componentWillMount() {
        if (!this.props.accounts && !this.props.isLoadingAccounts) {
            this.props.loadAccounts();
        }
    }

    searchAccounts() {
        this.props.loadAccounts(this.state.query);
    }

    handlePossibleSearchOp(event) {
        if (event.key === 'Enter') {
            this.searchAccounts();
        }
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <TeacherSidebar
                            pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}
                            administrator={this.props.accountType === 'admin'}/>
                    </div>

                    <div className="col-12 col-md-8 col-lg-9">
                        {this.renderPage()}
                    </div>
                </div>
            </div>
        );
    }

    renderPage() {

        return (
            <div>

                <h2 className="mb-4">{config.APP_NAME}</h2>
                {(!this.props.accounts) ? <LoadingSpinner/> : ''}

                {(this.props.accounts && this.props.accounts.length > 0) ? (
                    <div className="row">
                        <div className="col-12">


                            <div className="row">
                                <div className="col-12 col-sm-8 offset-sm-4 col-md-5 offset-md-7">


                                    <div className="form-group mb-5 mt-3">
                                        <label htmlFor="query">Search</label>
                                        <input type="text" className="form-control" id="query" name="query"
                                               placeholder="Username, email..." value={this.state.query}
                                               onChange={event => this.setState({query: event.target.value})}
                                               onKeyPress={(event) => this.handlePossibleSearchOp(event)}/>
                                    </div>


                                </div>
                            </div>


                            <table className="table table-sm table-striped">
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Username</th>
                                    <th>Full Name</th>
                                    <th>E-mail</th>
                                    <th>Type</th>
                                </tr>
                                </thead>

                                <tbody>
                                {(this.props.accounts.map(account => (
                                    <tr key={account.id} style={{cursor: 'pointer'}}
                                        onClick={() => this.props.history.push(`/admin/accounts/edit/${account.id}`)}>
                                        <td>{account.id}</td>
                                        <td>{account.username}</td>
                                        <td>{account.fullName}</td>
                                        <td>{account.email}</td>
                                        <td style={{textTransform: 'capitalize'}}>{account.type}</td>
                                    </tr>
                                )))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : ''}
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        accounts: state.adminAccounts.accounts,
        isLoadingAccounts: state.adminAccounts.isLoadingAccounts,
        failedLoadingAccounts: state.adminAccounts.failedLoadingAccounts,

        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadAccounts: (query) => {
            dispatch(loadAccounts(query));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAccountsPage);
