import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import auth from './auth';
import teacherTasks from './teacher-tasks';
import teacherArticles from "./teacher-articles";
import teacherEvents from "./teacher-events";

import studentEvents from "./student-events";
import studentSubmissions from "./student-submissions";

import adminAccounts from "./admin-accounts";

export default (history) => combineReducers({
    router: connectRouter(history),
    auth,

    teacherEvents,
    teacherTasks,
    teacherArticles,

    studentEvents,
    studentSubmissions,

    adminAccounts
});
