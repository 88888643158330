import React from 'react';
import {Link} from 'react-router-dom';

import PropTypes from 'prop-types';

import './styles.css';
import {standardEventUpdates} from "../../store/student-events";
import {connect} from "react-redux";


class SmartNavLink extends React.Component {

    render() {

        var isActive = this.props.pathname === this.props.to;

        var className = isActive ? ' active' : '';

        return (
            <Link className={this.props.className + className} to={this.props.to}>
                {this.props.children}
            </Link>
        );
    }
}


class StudentEventSidebar extends React.Component {

    state = {
        lastRendered: null
    };

    calculateTime(props) {

        let event = props.event;

        let secondsRemaining = event.timeRemainingSecs;
        let milisecondsElapsed = ((new Date()).getTime()) - event.receivingDate;

        secondsRemaining -= (milisecondsElapsed / 1000);
        secondsRemaining = Math.round(secondsRemaining);

        if (secondsRemaining < 0) {
            props.standardEventUpdates(event.id);
            return 'Event finished.';
        }


        let hours = Math.floor(secondsRemaining / 3600);
        let minutes = Math.floor((secondsRemaining - hours * 3600) / 60);

        return hours + ' hours, ' + minutes + ' mins';
    }


    componentDidMount() {
        this.intervalId = setInterval(() => {
            this.setState({
                lastRendered: new Date()
            });

        }, 12 * 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }


    render() {
        let props = this.props;

        return (
            <div>
                {(props.event
                    ? (
                        <div className="student-event-sidebar text-left">

                            <h4>Activities</h4>
                            <ul className="list-group">

                                {(props.event.articles.map(article => (
                                    <SmartNavLink pathname={props.pathname}
                                                  to={`/student/events/${props.event.id}/article/${article.id}`}
                                                  className="list-group-item d-flex justify-content-between align-items-center">
                                        {article.name}
                                        <span className="badge badge-primary badge-pill">A</span>
                                    </SmartNavLink>
                                )))}


                                {(props.event.tasks.map(task => (
                                    <SmartNavLink pathname={props.pathname}
                                                  to={`/student/events/${props.event.id}/task/${task.id}`}
                                                  className="list-group-item d-flex justify-content-between align-items-center">
                                        {task.name}
                                        <span className="badge badge-primary badge-pill">T</span>
                                    </SmartNavLink>
                                )))}

                            </ul>


                            <h4 className="mt-5">Event details</h4>
                            <ul className="list-group">

                                <SmartNavLink pathname={props.pathname} to={`/student/events/${props.event.id}`}
                                              className="list-group-item d-flex justify-content-between align-items-center">
                                    Contest info
                                    <span className="badge badge-default badge-pill">I</span>
                                </SmartNavLink>

                                <SmartNavLink pathname={props.pathname}
                                              to={`/student/events/${props.event.id}/submissions`}
                                              className="list-group-item d-flex justify-content-between align-items-center">
                                    Submissions
                                    <span className="badge badge-default badge-pill">I</span>
                                </SmartNavLink>


                                <SmartNavLink pathname={props.pathname}
                                              to={`/student/events/${props.event.id}/questions`}
                                              className="list-group-item d-flex justify-content-between align-items-center">
                                    Questions
                                    <span className="badge badge-default badge-pill">I</span>
                                </SmartNavLink>

                                <SmartNavLink pathname={props.pathname} to={`/student/events/${props.event.id}/results`}
                                              className={`list-group-item ${(!props.event.visibleResults ? ' d-none' : 'd-flex')} justify-content-between align-items-center`}>
                                    Results {props.event.visibleResults}
                                    <span className="badge badge-default badge-pill">I</span>
                                </SmartNavLink>
                            </ul>


                            <h4 className="mt-5">{(props.event && (props.event.status === 'analysis' || props.event.status === 'finished')) ? 'Status' : 'Time left'}</h4>
                            <ul className="list-group mb-5">
                                <li className="list-group-item">
                                    {(props.event && props.event.status === 'finished') ? 'Event finished' : ''}
                                    {(props.event && props.event.status === 'analysis') ? 'Analysis mode' : ''}
                                    {(props.event && props.event.status === 'running') ? this.calculateTime(props) : ''}
                                </li>
                            </ul>


                        </div>
                    ) : '')}

            </div>
        );
    }
}

StudentEventSidebar.propTypes = {
    event: PropTypes.object,
    pathname: PropTypes.string
};


function mapDispatchToProps(dispatch) {
    return {
        standardEventUpdates: (eventId) => {
            dispatch(standardEventUpdates(eventId));
        }
    };
}

export default connect(null, mapDispatchToProps)(StudentEventSidebar);
