import React from 'react';

import './styles.css';
import config from '../../config/default.json';
import {connect} from 'react-redux';

import LoadingSpinner from "../../components/LoadingSpinner";
import UploadModal from "../../components/UploadModal";

import {createTask, loadTask, loadTasks, updateTask} from '../../store/teacher-tasks';


import Select from 'react-select';
import RichTextEditor from "react-rte";
import TeacherSidebar from "../../components/TeacherSidebar";
import Card from "../../components/Card";


class TeacherAddTaskPage extends React.Component {

    testsFileRef = React.createRef();
    solutionsFileRef = React.createRef();

    parsingInfoTimer = undefined;

    state = {
        title: '',
        contents: '',
        tags: [],

        comparison: 'token',

        scoringType: 'sum',
        scoringParameters: '10',

        private: 'true',
        approved: 'false',
        timeLimit: 1000,
        memoryLimit: 64 * 1024,

        solutionDescription: null,
        solutionSourceCode: null,


        taskId: undefined,
        loadedTask: false,

        visibleEditingNav: 'configuration',
        showEditingNavs: false,

        uploadModalIsActive: false,
        editorValue: RichTextEditor.createEmptyValue(),
        solutionEditorValue: RichTextEditor.createEmptyValue(),
        internalError: null
    };

    componentWillMount() {

        if (!this.props.tasks && !this.props.isLoadingTasks) {
            this.props.loadTasks();
        }

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            this.goToEditingMode(this.props);
        }
    }

    goToEditingMode(nextProps) {

        this.setState({
            loadedTask: false,
            showEditingNavs: true,
            visibleEditingNav: (nextProps && nextProps.location && nextProps.location.search && nextProps.location.search.indexOf('parsing') >= 0 ? 'parsing-info' : 'configuration'),
            taskId: nextProps.match.params.id
        }, () => {

            this.props.loadTask(this.state.taskId);
            this.loadTaskContents();
        });
    }

    componentDidUpdate() {
        if (this.state.visibleEditingNav && this.state.visibleEditingNav === 'parsing-info') {
            if (!this.parsingInfoTimer) {
                this.parsingInfoTimer = setInterval(() => {
                    this.props.loadTask(this.state.taskId);
                }, 7500);
            }
        }
    }

    componentWillUnmount() {
        if (this.parsingInfoTimer) {
            clearInterval(this.parsingInfoTimer);
            this.parsingInfoTimer = undefined;
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.task) {
            if (nextProps && nextProps.match && nextProps.match.params && nextProps.match.params.id) {
                let newTaskId = nextProps.match.params.id;

                if (nextProps.task.id != newTaskId) {

                    this.goToEditingMode(nextProps);

                    if (this.parsingInfoTimer) {
                        clearInterval(this.parsingInfoTimer);
                        this.parsingInfoTimer = undefined;
                    }

                } else {
                    this.loadTaskContents(nextProps);
                }
            }
        }
    }

    loadTaskContents(newProps) {
        let props = newProps || this.props;
        let taskId = parseInt(this.state.taskId, 10);

        let task = props.task;

        if (task && task.id == taskId) {

            this.setState({
                title: task.name,
                contents: task.contents,
                tags: task.tags.map(t => ({value: t, label: t})),

                private: task.privateItem ? 'true' : 'false',
                approved: task.approvedByAdmin ? 'true' : 'false',
                comparison: task.comparison,
                timeLimit: task.timeLimit,
                memoryLimit: task.memoryLimit,

                scoringParameters: task.scoringParameters,
                scoringType: task.scoringType,

                solutionDescription: task.solutionDescription || '',
                solutionSourceCode: task.solutionSourceCode || '',

                loadedTask: true,
                editorValue: RichTextEditor.createValueFromString(task.contents, 'markdown'),
                solutionEditorValue: RichTextEditor.createValueFromString(task.solutionDescription || '', 'markdown')
            });
        }
    }

    taskTags() {

        return config.TASK_TAGS.map(tag => ({
            value: tag,
            label: tag
        }));
    }

    taskData() {

        let {title, tags, contents, comparison, timeLimit, memoryLimit, solutionDescription, solutionSourceCode, scoringType, scoringParameters, approved} = this.state;
        let tests = (this.testsFileRef.current.files.length > 0 ? this.testsFileRef.current.files[0] : null);
        let solutions = (this.solutionsFileRef.current.files.length > 0 ? this.solutionsFileRef.current.files : null);

        return {
            name: title,
            tags: tags.map(t => t.value).join(';'),
            contents,
            comparison,
            timeLimit,
            memoryLimit,
            solutionDescription,
            solutionSourceCode,
            scoringType,
            scoringParameters,
            "private": this.state.private,
            approved,
            tests,
            solutions
        };
    }


    submitForm() {

        this.setState({internalError: null}, () => {

            if (!this.state.title || !this.state.contents || !this.state.comparison) {
                this.setState({internalError: 'Please complete all fields and try again!'});
                return /* don't submit form */;
            }

            if (!this.state.taskId) {
                this.props.createTask(this.taskData());
            } else {
                this.props.updateTask(this.state.taskId, this.taskData());
            }

        });
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <TeacherSidebar
                            pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}
                            administrator={this.props.accountType === 'admin'}/>
                    </div>

                    <div className="col-12 col-md-8 col-lg-9">
                        {this.renderPage()}
                    </div>
                </div>
            </div>
        );
    }


    renderPage() {

        return (
            <div>


                {(this.state.taskId && !this.props.tasks) ? <LoadingSpinner/> : (
                    <form className="form data-form mt-4" style={{opacity: this.props.isUpdatingTask ? 0.3 : 1}}
                          onSubmit={(task) => {
                              task.preventDefault();
                              this.submitForm();
                          }}>
                        <h2 className="text-center mt-1 mb-4">{this.state.taskId ? 'Edit task' : 'Add task'}</h2>


                        <ul className="nav nav-pills">
                            <li className="nav-item">
                                <a href="#"
                                   className={"nav-link" + (this.state.visibleEditingNav === 'configuration' ? " active" : "")}
                                   onClick={(event) => {
                                       event.preventDefault();
                                       this.setState({visibleEditingNav: 'configuration'});
                                   }}>Configuration</a>
                            </li>
                            <li className="nav-item">
                                <a href="#"
                                   className={"nav-link" + (this.state.visibleEditingNav === 'analysis' ? " active" : "")}
                                   onClick={(event) => {
                                       event.preventDefault();
                                       this.setState({visibleEditingNav: 'analysis'});
                                   }}>Analysis</a>
                            </li>
                            <li className="nav-item">
                                <a href="#"
                                   className={"nav-link" + (this.state.visibleEditingNav === 'parsing-info' ? " active" : "")}
                                   onClick={(event) => {
                                       event.preventDefault();
                                       this.setState({visibleEditingNav: 'parsing-info'});
                                   }}>Parsing info</a>
                            </li>
                        </ul>


                        <div style={{display: (this.state.visibleEditingNav === 'configuration' ? 'block' : 'none')}}>
                            {(this.state.internalError || this.props.failedUpdatingTask) ?
                                <p className="form-error">{this.state.internalError || this.props.error || 'Failed to save task. Please try again.'}</p> : ''}


                            <Card title="Basic info">
                                <div className="form-group">
                                    <label htmlFor="title">Title</label>
                                    <input type="text" className="form-control" id="title" name="title" autoFocus
                                           placeholder="Enter a title" value={this.state.title}
                                           onChange={event => this.setState({title: event.target.value})}/>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="private">Task visibility</label>

                                    <select className="form-control" id="private" name="private"
                                            value={this.state.private}
                                            onChange={event => this.setState({private: event.target.value})}>

                                        <option value="true">Private (only visible to you)</option>
                                        <option value="false">Public (visible to all teachers)</option>
                                    </select>
                                </div>


                                {(this.props.accountType === 'admin')
                                    ? (<div className="form-group">
                                        <label htmlFor="approved">Approved for public</label>

                                        <select className="form-control" id="approved" name="approved"
                                                value={this.state.approved}
                                                onChange={event => this.setState({approved: event.target.value})}>

                                            <option value="true">Publicly visible (all teachers can see it)</option>
                                            <option value="false">Not approved (visible to creator only)</option>
                                        </select>
                                    </div>)
                                    : ''}


                                <div className="form-group">
                                    <label htmlFor="timeLimit">Time limit (ms)</label>
                                    <input type="text" className="form-control" id="timeLimit" name="timeLimit"
                                           placeholder="Enter the time limit (in ms)" value={this.state.timeLimit}
                                           onChange={event => this.setState({timeLimit: event.target.value})}/>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="memoryLimit">Memory limit (KB)</label>
                                    <input type="text" className="form-control" id="memoryLimit" name="memoryLimit"
                                           placeholder="Enter the memory limit (in KB)" value={this.state.memoryLimit}
                                           onChange={event => this.setState({memoryLimit: event.target.value})}/>
                                </div>
                            </Card>


                            <Card title="Contents">
                                <div className="form-group">
                                    <label htmlFor="tags">Tags</label>
                                    <div>
                                        <Select value={this.state.tags} onChange={value => this.setState({tags: value})}
                                                options={this.taskTags()} isMulti={true}/>
                                    </div>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="content">Task contents</label>


                                    <RichTextEditor
                                        className={'rich-text-editor'}
                                        value={this.state.editorValue}
                                        onChange={(value) => {
                                            this.setState({contents: value.toString('markdown'), editorValue: value});
                                        }}
                                    />
                                </div>


                                <div style={{textAlign: 'right', marginTop: '7px', marginBottom: '30px'}}>
                                    <a href="#" onClick={(event) => {
                                        event.preventDefault();
                                        this.setState({uploadModalIsActive: true});
                                    }}>Upload image or file</a>
                                    <UploadModal open={this.state.uploadModalIsActive} onClose={() => {
                                        this.setState({uploadModalIsActive: false});
                                    }}/>
                                </div>
                            </Card>


                            <Card title="Testing">
                                <div className="form-group">
                                    <label htmlFor="comparison">Output comparison</label>

                                    <select className="form-control" id="comparison" name="comparison"
                                            value={this.state.comparison}
                                            onChange={event => this.setState({comparison: event.target.value})}>

                                        <option value="token">Tokens (for standard tasks)</option>
                                        <option value="value">Values (floating-point outputs)</option>
                                    </select>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="tests">Tests archive</label>
                                    <input type="file" className="form-control-file" id="tests" name="tests"
                                           ref={this.testsFileRef}/>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="solutions">Solution codes</label>
                                    <input type="file" className="form-control-file" id="solutions" name="solutions"
                                           ref={this.solutionsFileRef} multiple/>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="scoringType">Scoring type</label>

                                    <select className="form-control" id="scoringType" name="scoringType"
                                            value={this.state.scoringType}
                                            onChange={event => this.setState({scoringType: event.target.value})}>

                                        <option value="sum">Sum (sum scores for each test)</option>
                                        <option value="groupMin">GroupMin (all tests in a group should be correct)
                                        </option>
                                    </select>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="scoringParameters">Scoring parameters</label>
                                    <input type="text" className="form-control" id="scoringParameters"
                                           name="scoringParameters"
                                           placeholder="Enter the scoring parameters (points per test)"
                                           value={this.state.scoringParameters}
                                           onChange={event => this.setState({scoringParameters: event.target.value})}/>
                                </div>
                            </Card>


                            <div className="text-right">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>


                        <div style={{display: (this.state.visibleEditingNav === 'analysis' ? 'block' : 'none')}}>
                            {(this.state.internalError || this.props.failedUpdatingTask) ?
                                <p className="form-error">{this.state.internalError || this.props.error || 'Failed to save task. Please try again.'}</p> : ''}

                            <Card title="Solution">
                                <div className="form-group">
                                    <label htmlFor="content">Solution description</label>


                                    <RichTextEditor
                                        className={'rich-text-editor'}
                                        value={this.state.solutionEditorValue}
                                        onChange={(value) => {
                                            this.setState({
                                                solutionDescription: value.toString('markdown'),
                                                solutionEditorValue: value
                                            });
                                        }}
                                    />
                                </div>


                                <div className="form-group">
                                    <label htmlFor="solutionSourceCode">Source code:</label>
                                    <textarea className="form-control" id="solutionSourceCode" name="solutionSourceCode"
                                              value={this.state.solutionSourceCode}
                                              onChange={(event) => this.setState({solutionSourceCode: event.target.value})}
                                              rows={7}></textarea>
                                </div>
                            </Card>


                            <div className="text-right">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>


                        <div style={{
                            paddingTop: '20px',
                            display: (this.state.visibleEditingNav === 'parsing-info' ? 'block' : 'none')
                        }}>

                            <Card title="Live updates">

                                {this.props.task && this.props.task.parsingInfo ? ((typeof this.props.task.parsingInfo === 'string' || this.props.task.parsingInfo instanceof String) ? JSON.parse(this.props.task.parsingInfo) : this.props.task.parsingInfo).map(row => (

                                    <p className={`mt-1 mb-0 parsing-info parsing-info-${row.diff ? 'jump' : 'continue'}`}>{row.status === 'success' ? (
                                            <span className="badge badge-success">{row.timestamp || 'success'}</span>) :
                                        <span
                                            className="badge badge-danger">{row.timestamp || row.status}</span>} &nbsp; {row.message}</p>

                                )) : ''}

                            </Card>
                        </div>


                    </form>
                )}

            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        error: state.teacherTasks.lastError,
        task: state.teacherTasks.task,
        tasks: state.teacherTasks.tasks,
        isUpdatingTask: state.teacherTasks.isUpdatingTask,
        failedUpdatingTask: state.teacherTasks.failedUpdatingTask,

        isLoadingTasks: state.teacherTasks.isLoadingTasks,
        failedLoadingTasks: state.teacherTasks.failedLoadingTasks,

        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadTask: (id) => {
            dispatch(loadTask(id));
        },
        loadTasks: () => {
            dispatch(loadTasks());
        },
        createTask: (settings) => {
            dispatch(createTask(settings));
        },
        updateTask: (taskId, settings) => {
            dispatch(updateTask(taskId, settings));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherAddTaskPage);
