import React from 'react';

import {loadEvent, standardEventUpdates} from '../../store/student-events';
import {loadSubmission} from '../../store/student-submissions';

import {connect} from 'react-redux';

import config from '../../config/default.json';

import "./styles.css";

import LoadingSpinner from "../../components/LoadingSpinner";
import SubmissionData from "../../components/SubmissionData";
import StudentEventSidebar from '../../components/StudentEventSidebar';


class StudentEventSubmissionPage extends React.Component {

    componentWillMount() {
        let eventId = this.props.match.params.id;

        if ((!this.props.event || this.props.event.id != eventId) && !this.props.isLoadingEvent) {
            if (this.props.event) {
                this.props.loadEvent(eventId);
            } else {
                setTimeout(() => {
                    this.props.loadEvent(eventId);
                }, 1000);
            }
        }

        if (this.props.event) {
            this.loadData(this.props);
        }
    }


    componentDidMount() {
        let eventId = this.props.match.params.id;

        if (this.props.event && this.props.event.id == eventId) {
            this.props.standardEventUpdates(eventId);
        }

        this.intervalId = setInterval(() => {

            if (Math.random() * 100 < 20) { //one-fifth of the time, because we run this every 7 secs
                if (this.props.event && this.props.event.id == eventId) {
                    this.props.standardEventUpdates(eventId);
                }
            }

            if (this.props.submission && this.props.submission.score === null) {
                this.loadData(this.props, true);
            }

        }, 7 * 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }


    componentWillReceiveProps(newProps) {

        let eventId = this.props.match.params.id;
        if (newProps.event && newProps.event.id == eventId && newProps.event.format && newProps.event.status !== 'running' && newProps.event.status !== 'analysis') {
            this.props.history.push(`/student/events/${eventId}`)
        }

        if (newProps.event && newProps.event.id != eventId && !newProps.isLoadingEvent) {
            this.props.loadEvent(eventId);
        }

        this.loadData(newProps);
    }


    loadData(newProps, forceReload) {
        let eventId = this.props.match.params.id;
        let submissionId = this.props.match.params.submissionId;

        if (newProps.event && newProps.event.id == eventId && (newProps.submission == null || newProps.submission.id != submissionId || forceReload) && !newProps.isLoadingSubmission && !newProps.failedLoadingSubmission) {
            this.props.loadSubmission(submissionId);
        }
    }


    render() {

        return (
            <div className="container submission-container">

                <div className="row">
                    <div className="col text-center">


                        {(!this.props.event) ? (
                            <div>
                                <h2 className="mb-4">{config.APP_NAME}</h2>
                                <LoadingSpinner/>
                            </div>) : ''}


                        {(this.props.event) ? (

                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-3">
                                    <StudentEventSidebar event={this.props.event}
                                                         pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}/>
                                </div>

                                <div className="col-12 col-md-8 col-lg-9">

                                    <div className="text-left">
                                        <h2 className="mb-4">{this.props.event.name}</h2>

                                        <SubmissionData submission={this.props.submission}/>
                                    </div>
                                </div>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        event: state.studentEvents.event,
        isLoadingEvent: state.studentEvents.isLoadingEvent,
        failedLoadingEvent: state.studentEvents.failedLoadingEvent,

        submission: state.studentSubmissions.submission,
        isLoadingSubmission: state.studentSubmissions.isLoadingSubmission,
        failedLoadingSubmission: state.studentSubmissions.failedLoadingSubmission,

        error: state.studentEvents.lastError
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadEvent: (eventId) => {
            dispatch(loadEvent(eventId));
        },
        standardEventUpdates: (eventId) => {
            dispatch(standardEventUpdates(eventId));
        },
        loadSubmission: (submissionId) => {
            dispatch(loadSubmission(submissionId));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentEventSubmissionPage);
