import React from 'react';
import PropTypes from 'prop-types';

import {UnControlled as CodeMirror} from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/clike/clike';

import Card from "../../components/Card";
import SubmissionTestDataModal from "../../components/SubmissionTestDataModal";

class SubmissionData extends React.Component {

    state = {
        submissionDataModalTest: {},
        submissionDataModalIsOpen: false,
        screen: 'submission'
    };


    verdictMessage(verdict) {

        switch (verdict) {

            case 'ok':
                return 'Correct answer';
                break;
            case 'wa':
                return 'Wrong answer';
                break;
            case 'tle':
                return 'Time limit exceeded';
                break;
            default:
                return 'Runtime error (exit code is not 0)';
                break;
        }

    }


    timeUsage(time) {

        return (Number(time) / 1000.0).toFixed(2) + ' sec.';
    }


    render() {

        return (

            <div>
                <SubmissionTestDataModal test={this.state.submissionDataModalTest}
                                         open={this.state.submissionDataModalIsOpen}
                                         onClose={() => this.setState({submissionDataModalIsOpen: false})}/>


                <ul className="nav nav-pills mr-auto">
                    <li className="nav-item">
                        <a href="#" className={"nav-link" + (this.state.screen === 'submission' ? ' active' : '')}
                           onClick={(event) => {
                               event.preventDefault();
                               this.setState({screen: 'submission'})
                           }}>Submission info</a>
                    </li>
                    <li className="nav-item">
                        <a href="#" className={"nav-link" + (this.state.screen === 'sourceCode' ? ' active' : '')}
                           onClick={(event) => {
                               event.preventDefault();
                               this.setState({screen: 'sourceCode'})
                           }}>Source code</a>
                    </li>
                </ul>


                {this.state.screen === 'submission' && (!this.props.submission || this.props.submission.score === null || this.props.submission.result === null)
                    ? (<div>
                        <p className="mt-5 mb-6 pb-4 text-center">Submission is being evaluated<br/>Please wait...</p>

                        <div className="mt-4 mb-5 text-center">
                            <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}}
                                 role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>)
                    : ''}


                {this.state.screen === 'submission' && (this.props.submission && this.props.submission.score !== null && this.props.submission.result !== null)
                    ? (<div>


                        <p className="text-right">Score &nbsp;<span className="badge badge-danger mt-0"
                                                                    style={{fontSize: '1.5rem'}}>{this.props.submission.score}</span>
                            {(this.props.submission && this.props.submission.analysisMode) ? (
                                <span className="badge badge-secondary mt-0 ml-2" style={{fontSize: '1.5rem'}}
                                      title="Submission during analysis mode.">Analysis</span>) : ''}
                        </p>


                        {(this.props.submission.result.compiled)
                            ? (
                                <div>
                                    {((this.props.submission.result.subtasks || []).map(subtask => (

                                        <Card title={subtask.name}>

                                            <div className="pl-2">
                                                <table className="table table-sm table-striped table-border">

                                                    <tr>
                                                        <th className="text-right"
                                                            style={{borderTop: '0'}}># &nbsp;</th>
                                                        <th style={{borderTop: '0'}}>Verdict</th>
                                                        <th style={{borderTop: '0'}}>Time</th>
                                                    </tr>

                                                    {subtask.tests.map((testId, pos) => {

                                                        let test = this.props.submission.result.tests[testId] || {};

                                                        return (
                                                            <tr className={test.verdict === 'ok' ? 'table-success-default' : 'table-danger-default'}>
                                                                <td className="text-right">{pos + 1}.&nbsp;</td>
                                                                <td>{this.verdictMessage(test.verdict)} &nbsp;
                                                                    {(test.verdict === 'ok'
                                                                        ? (<span style={{
                                                                            fontWeight: 'bold',
                                                                            color: 'green'
                                                                        }}>&#10004;</span>)
                                                                        : (<span
                                                                            style={{color: '#ac0000'}}>&#10060;</span>))}

                                                                    {(test.input && test.correctOutput)
                                                                        ? (
                                                                            <span style={{
                                                                                paddingLeft: '20px',
                                                                                float: 'right',
                                                                                paddingRight: '20px',
                                                                                cursor: 'pointer',
                                                                                color: '#0d3769'
                                                                            }} onClick={() => {
                                                                                this.setState({
                                                                                    submissionDataModalTest: test,
                                                                                    submissionDataModalIsOpen: true
                                                                                })
                                                                            }}>[ view data ]</span>
                                                                        )
                                                                        : ''}
                                                                </td>
                                                                <td>{(test.verdict && test.verdict === 'tle') ? 'TLE' : this.timeUsage(test.cpuTimeUsage)}</td>
                                                            </tr>
                                                        );

                                                    })}

                                                </table>
                                            </div>
                                        </Card>
                                    )))}
                                </div>
                            )
                            : (

                                <div>

                                    <Card title="Compilation error" classes="bg-danger text-white">
                                                            <pre>
                                                                {this.props.submission.result.compilerOutput}
                                                            </pre>
                                    </Card>

                                </div>

                            )}


                    </div>)
                    : ''}


                {this.state.screen === 'sourceCode' && this.props.submission && this.props.submission.sourceCode
                    ? (<div style={{border: '1px solid #e7e7e7', marginTop: '25px'}}>
                        <CodeMirror
                            value={this.props.submission.sourceCode}
                            options={{
                                readOnly: true,
                                mode: (this.props.submission.language === 'cpp' ? 'text/x-c++src' : (this.props.submission.language === 'c' ? 'text/x-csrc' : 'text/x-java')),
                                lines: 50,
                                lineNumbers: true
                            }}
                        />
                    </div>)
                    : ''}
            </div>

        );
    }
}


SubmissionData.propTypes = {
    submission: PropTypes.object
};

export default SubmissionData;
