import React from 'react';

import {connect} from 'react-redux';
import LoadingSpinner from "../../components/LoadingSpinner";

import {loadAccounts, updateAccount} from '../../store/admin-accounts';
import TeacherSidebar from "../../components/TeacherSidebar";
import Card from "../../components/Card";


class AdminEditAccountPage extends React.Component {
    state = {
        username: '',
        fullName: '',

        email: '',
        type: 'student',

        accountId: undefined,
        loadedAccount: false,

        internalError: null
    };

    componentWillMount() {

        if (!this.props.accounts && !this.props.isLoadingAccounts) {
            this.props.loadAccounts();
        }

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            this.setState({
                accountId: this.props.match.params.id
            }, () => {

                this.loadAccountContents();
            });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.accounts && this.state.accountId) {
            this.loadAccountContents(nextProps);
        }
    }

    loadAccountContents(newProps) {
        let props = newProps || this.props;
        let accountId = parseInt(this.state.accountId, 10);

        let account = (props.accounts || []).find(a => (a.id == accountId));

        if (account && account.id == accountId) {

            this.setState({
                username: account.username,
                fullName: account.fullName,

                email: account.email,
                type: account.type,

                loadedAccount: true
            });
        }
    }

    submitForm() {

        this.setState({internalError: null}, () => {

            if (!this.state.username || !this.state.fullName) {
                this.setState({internalError: 'Please complete all fields and try again!'});
                return /* don't submit form */;
            }

            this.props.updateAccount(this.state.accountId, this.state.username, this.state.fullName, this.state.email, this.state.type);

        });
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <TeacherSidebar
                            pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}
                            administrator={this.props.accountType === 'admin'}/>
                    </div>

                    <div className="col-12 col-md-8 col-lg-9">
                        {this.renderPage()}
                    </div>
                </div>
            </div>
        );
    }

    renderPage() {

        return (
            <div>

                {(this.state.accountId && !this.state.loadedAccount) ? <LoadingSpinner/> : (
                    <form className="form data-form mt-4" style={{opacity: this.props.isUpdatingAccount ? 0.3 : 1}}
                          onSubmit={(event) => {
                              event.preventDefault();
                              this.submitForm();
                          }}>
                        <h2 className="text-center mt-1 mb-4">{this.state.accountId ? 'Edit account' : 'Add account'}</h2>

                        {(this.state.internalError || this.props.failedUpdatingAccount) ?
                            <p className="form-error">{this.state.internalError || this.props.error || 'Failed to save account. Please try again.'}</p> : ''}

                        <Card title="Information">
                            <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <input type="text" className="form-control" id="username" name="username" autoFocus
                                       placeholder="Enter a username" value={this.state.username}
                                       onChange={event => this.setState({username: event.target.value})}/>
                            </div>


                            <div className="form-group">
                                <label htmlFor="fullName">Full Name</label>
                                <input type="text" className="form-control" id="fullName" name="fullName"
                                       placeholder="Enter the user's full name" value={this.state.fullName}
                                       onChange={event => this.setState({fullName: event.target.value})}/>
                            </div>


                            <div className="form-group">
                                <label htmlFor="email">Email address</label>
                                <input type="email" className="form-control" id="email" name="email"
                                       placeholder="Email address" value={this.state.email}
                                       onChange={event => this.setState({email: event.target.value})}/>
                            </div>


                            <div className="form-group">
                                <label htmlFor="type">Account type</label>

                                <select className="form-control" id="type" name="type" value={this.state.type}
                                        onChange={event => this.setState({type: event.target.value})}>

                                    <option value="user">Student (regular user)</option>
                                    <option value="teacher">Teacher (can create tasks, articles and events)</option>
                                    <option value="admin">Administrator (full system access)</option>
                                </select>
                            </div>
                        </Card>

                        <div className="text-right">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                )}

            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        error: state.adminAccounts.lastError,
        accounts: state.adminAccounts.accounts,
        isUpdatingAccount: state.adminAccounts.isUpdatingAccount,
        failedUpdatingAccount: state.adminAccounts.failedUpdatingAccount,

        isLoadingAccounts: state.adminAccounts.isLoadingAccounts,
        failedLoadingAccounts: state.adminAccounts.failedLoadingAccounts,

        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadAccounts: () => {
            dispatch(loadAccounts());
        },
        updateAccount: (accountId, username, fullName, email, type) => {
            dispatch(updateAccount(accountId, username, fullName, email, type));
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditAccountPage);
