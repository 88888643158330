import request from '../lib/request.js';
import {LOCATION_CHANGE, push} from 'connected-react-router';


export const LOAD_ACCOUNTS_STARTED = 'adminAccounts/LOAD_ACCOUNTS_STARTED';
export const LOAD_ACCOUNTS_FAILED = 'adminAccounts/LOAD_ACCOUNTS_FAILED';
export const LOAD_ACCOUNTS_FINISHED = 'adminAccounts/LOAD_ACCOUNTS_FINISHED';

export const EDIT_ACCOUNT_STARTED = 'adminAccounts/EDIT_ACCOUNT_STARTED';
export const EDIT_ACCOUNT_FAILED = 'adminAccounts/EDIT_ACCOUNT_FAILED';
export const EDIT_ACCOUNT_FINISHED = 'adminAccounts/EDIT_ACCOUNT_FINISHED';


const initialState = {
    accounts: null,

    isLoadingAccounts: false,
    failedLoadingAccounts: false,

    isUpdatingAccount: false,
    failedUpdatingAccount: false,

    lastError: null
};

export default (state = initialState, action) => {
    switch (action.type) {

        case LOCATION_CHANGE:
            return {
                ...state,
                failedLoadingAccounts: false,
                failedUpdatingAccount: false
            };

        case LOAD_ACCOUNTS_STARTED:
            return {
                ...state,
                lastError: null,
                accounts: null,
                isLoadingAccounts: true,
                failedLoadingAccounts: false
            };

        case LOAD_ACCOUNTS_FAILED:
            return {
                ...state,
                accounts: null,
                lastError: action.error || null,
                isLoadingAccounts: false,
                failedLoadingAccounts: new Date()
            };

        case LOAD_ACCOUNTS_FINISHED:
            return {
                ...state,
                accounts: action.accounts,
                lastError: null,
                isLoadingAccounts: false,
                failedLoadingAccounts: false
            };

        case EDIT_ACCOUNT_STARTED:
            return {
                ...state,
                lastError: null,
                isUpdatingAccount: true,
                failedUpdatingAccount: false
            };

        case EDIT_ACCOUNT_FAILED:
            return {
                ...state,
                lastError: action.error || null,
                isUpdatingAccount: false,
                failedUpdatingAccount: new Date()
            };

        case EDIT_ACCOUNT_FINISHED:
            return {
                ...state,
                lastError: null,
                accounts: state.accounts.slice().map(current => {
                    if (current.id === action.account.id) {
                        return action.account;
                    } else {
                        return current;
                    }
                }),

                isUpdatingAccount: false,
                failedUpdatingAccount: false
            };


        default:
            return state
    }
}


export const loadAccounts = (searchQuery) => {

    return dispatch => {
        dispatch({
            type: LOAD_ACCOUNTS_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.get('/admin/accounts' + (searchQuery ? '?search=' + encodeURIComponent(searchQuery) : ''), {headers: {Authentication: authenticationToken}})
            .then(({data}) => {


                dispatch({
                    type: LOAD_ACCOUNTS_FINISHED,
                    accounts: data
                });
            })
            .catch((error) => {
                dispatch({
                    type: LOAD_ACCOUNTS_FAILED
                });
            });
    }
};


export const updateAccount = (accountId, username, fullName, email, type) => {


    return dispatch => {
        dispatch({
            type: EDIT_ACCOUNT_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.post('/admin/accounts/edit/' + accountId, {
            username,
            fullName,
            email,
            type
        }, {headers: {Authentication: authenticationToken}})
            .then(({data}) => {

                dispatch({
                    type: EDIT_ACCOUNT_FINISHED,
                    account: data
                });

                dispatch(push('/admin/accounts'));
            })
            .catch((error) => {
                dispatch({
                    type: EDIT_ACCOUNT_FAILED,
                    error: (error.response && error.response.data ? error.response.data.message : error) || error
                });
            });
    }
};
