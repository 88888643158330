import React from 'react';

import "react-datepicker/dist/react-datepicker.css";


import {connect} from 'react-redux';
import LoadingSpinner from "../../components/LoadingSpinner";

import {loadEvents, loadQuestions, sendAnswerToEventQuestion} from '../../store/teacher-events';
import TeacherSidebar from "../../components/TeacherSidebar";


class TeacherEventQuestionsPage extends React.Component {
    state = {
        editingQuestionId: null,
        answerContents: '',
        publicAnswer: false,

        eventId: null,
        internalError: null,

        questions: null
    };

    componentWillMount() {

        if (!this.props.events && !this.props.isLoadingEvents) {
            this.props.loadEvents();
        }

        let newEventId = this.props.match.params.id;

        this.setState({eventId: newEventId}, () => {
            this.props.loadQuestions(newEventId);
        });
    }

    eventName() {
        let newEventId = this.props.match.params.id;
        if (this.props.events && this.props.events.find(e => (e.id == newEventId))) {
            return this.props.events.find(e => (e.id == newEventId)).name;
        }

        return undefined;
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.questions) {
            if (nextProps && nextProps.match && nextProps.match.params && nextProps.match.params.id) {
                let newEventId = nextProps.match.params.id;

                if ((!this.state.eventId || this.state.eventId != newEventId) && nextProps.questions[newEventId]) {
                    this.setState({eventId: newEventId}, () => {
                        this.loadEventQuestions(nextProps);
                    });

                } else {
                    this.loadEventQuestions(nextProps);
                }
            }
        }
    }

    loadEventQuestions(newProps) {
        let props = newProps || this.props;
        let eventId = parseInt(this.state.eventId, 10);

        if (!props || !props.questions || !props.questions[eventId]) {
            return /* for now */;
        }


        let questions = props.questions[eventId];

        this.setState({
            questions: questions
        });
    }


    submitForm() {

        this.setState({internalError: null}, () => {

            if (!this.state.answerContents) {
                this.setState({internalError: 'Please write an answer and try again!'});
                return /* don't submit form */;
            }

            let newEventId = this.props.match.params.id;
            this.props.sendAnswerToEventQuestion(newEventId, this.state.editingQuestionId, {
                answer: this.state.answerContents,
                public: (this.state.publicAnswer ? true : false)
            });

            setTimeout(() => {
                this.setState({
                    editingQuestionId: null,
                    answerContents: '',
                    publicAnswer: false
                });
            }, 250);
        });
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <TeacherSidebar
                            event={(this.state.eventId && this.props.events) ? (this.props.events.find(e => e.id == this.state.eventId)) : null}
                            pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}
                            administrator={this.props.accountType === 'admin'}/>
                    </div>

                    <div className="col-12 col-md-8 col-lg-9">
                        {this.renderPage()}
                    </div>
                </div>
            </div>
        );
    }


    renderPage() {
        return (
            <div>

                {((this.state.eventId && !this.props.events) || (this.state.questions == null)) ? <LoadingSpinner/> : (


                    <div className="text-left">
                        <h2 className="mb-4">{this.eventName() || 'Event Questions'}</h2>

                        {(this.state.questions.map(question => (

                            <div key={question.id} style={{marginBottom: '30px'}}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title mb-3"><span
                                            className="badge badge-primary">Q</span> {question.question}</h5>
                                        <p className="card-text">Answer: {question.answer || 'No answer provided yet.'}</p>


                                        {(this.state.editingQuestionId && this.state.editingQuestionId == question.id)
                                            ? (
                                                <div className="mt-3 mb-4 p-2" style={{backgroundColor: '#f5f5f5'}}>

                                                    <form>
                                                        <div className="form-group">
                                                            <label htmlFor="publicAnswer">Public answer</label>

                                                            <div className="custom-control custom-switch">
                                                                <input type="checkbox" className="custom-control-input"
                                                                       id="publicAnswer" name="publicAnswer"
                                                                       checked={this.state.publicAnswer}
                                                                       onChange={() => this.setState({publicAnswer: !this.state.publicAnswer})}/>
                                                                <label className="custom-control-label"
                                                                       htmlFor="publicAnswer">Answer is visible to
                                                                    everyone.</label>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="answer">Answer:</label>
                                                            <textarea className="form-control" id="answer" name="answer"
                                                                      value={this.state.answerContents}
                                                                      onChange={(event) => this.setState({answerContents: event.target.value})}
                                                                      rows={4}></textarea>
                                                        </div>


                                                        <div className="text-right text-muted mb-0">
                                                            <button className="btn btn-danger btn-sm"
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        this.submitForm()
                                                                    }}>Update
                                                            </button>
                                                        </div>
                                                    </form>

                                                </div>
                                            )
                                            : ''}


                                        <div
                                            className={"text-right text-muted mb-0" + ((this.state.editingQuestionId && this.state.editingQuestionId == question.id) ? ' d-none' : '')}>
                                            <button className="btn btn-primary btn-sm" onClick={(event) => {
                                                event.preventDefault();
                                                this.setState({
                                                    editingQuestionId: question.id,
                                                    answerContents: (question.answer || ''),
                                                    publicAnswer: (question.visibleToEveryone ? true : false)
                                                });
                                            }}>Answer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )))}


                        {(this.state.questions && this.state.questions.length === 0)
                            ? <p className="mt-5 mb-5 text-center">No questions to present.<br/>Please check back later.
                            </p>
                            : ''}
                    </div>


                )}

            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        error: state.teacherEvents.lastError,
        events: state.teacherEvents.events,
        questions: state.teacherEvents.questions,

        isLoadingEvents: state.teacherEvents.isLoadingEvents,
        failedLoadingEvents: state.teacherEvents.failedLoadingEvents,

        isLoadingTasks: state.teacherTasks.isLoadingTasks,
        failedLoadingTasks: state.teacherTasks.failedLoadingTasks,

        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadEvents: () => {
            dispatch(loadEvents());
        },
        loadQuestions: (eventId) => {
            dispatch(loadQuestions(eventId));
        },
        sendAnswerToEventQuestion: (eventId, questionId, data) => {
            dispatch(sendAnswerToEventQuestion(eventId, questionId, data));
        }

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherEventQuestionsPage);
