import React from 'react';
import {Link} from 'react-router-dom';

import PropTypes from 'prop-types';

import './styles.css';


class SmartNavLink extends React.Component {

    render() {

        var isActive = this.props.pathname === this.props.to;

        if (this.props.pathname.startsWith('/teacher/tasks/') && this.props.to.startsWith('/teacher/tasks')) {
            isActive = true;
        }

        if (this.props.pathname.startsWith('/teacher/articles/') && this.props.to.startsWith('/teacher/articles')) {
            isActive = true;
        }


        var className = isActive ? ' active' : '';

        return (
            <Link className={this.props.className + className} to={this.props.to}>
                {this.props.children}
            </Link>
        );
    }
}


const TeacherSidebar = (props) => (
    <div>
        {(props.pathname
            ? (
                <div className="teacher-sidebar text-left">

                    {(props.event)
                        ? (<div className="mb-5">

                            <h4>{props.event.name}</h4>
                            <ul className="list-group">

                                <SmartNavLink pathname={props.pathname} to={`/teacher/events/edit/${props.event.id}`}
                                              className="list-group-item d-flex justify-content-between align-items-center">
                                    Settings
                                    <span className="badge badge-default badge-pill">E</span>
                                </SmartNavLink>

                                <SmartNavLink pathname={props.pathname}
                                              to={`/teacher/events/${props.event.id}/questions`}
                                              className="list-group-item d-flex justify-content-between align-items-center">
                                    Questions
                                    <span className="badge badge-default badge-pill">E</span>
                                </SmartNavLink>


                                <SmartNavLink pathname={props.pathname} to={`/teacher/events/${props.event.id}/results`}
                                              className="list-group-item d-flex justify-content-between align-items-center">
                                    Results
                                    <span className="badge badge-default badge-pill">E</span>
                                </SmartNavLink>

                            </ul>

                        </div>)
                        : ''}

                    <h4>Contents</h4>
                    <ul className="list-group">

                        <SmartNavLink pathname={props.pathname} to={`/teacher/events`}
                                      className="list-group-item d-flex justify-content-between align-items-center">
                            Events
                            <span className="badge badge-default badge-pill">C</span>
                        </SmartNavLink>

                        <SmartNavLink pathname={props.pathname} to={`/teacher/articles`}
                                      className="list-group-item d-flex justify-content-between align-items-center">
                            Articles
                            <span className="badge badge-default badge-pill">C</span>
                        </SmartNavLink>


                        <SmartNavLink pathname={props.pathname} to={`/teacher/tasks`}
                                      className="list-group-item d-flex justify-content-between align-items-center">
                            Tasks
                            <span className="badge badge-default badge-pill">C</span>
                        </SmartNavLink>

                    </ul>


                    <h4 className="mt-5">More</h4>
                    <ul className="list-group">

                        <SmartNavLink pathname={props.pathname} to={`/`}
                                      className="list-group-item d-flex justify-content-between align-items-center">
                            Homepage
                            <span className="badge badge-info badge-pill">M</span>
                        </SmartNavLink>


                        <SmartNavLink pathname={props.pathname} to={`/student/events`}
                                      className="list-group-item d-flex justify-content-between align-items-center">
                            Students view
                            <span className="badge badge-info badge-pill">M</span>
                        </SmartNavLink>


                        {(props.administrator)
                            ? (<SmartNavLink pathname={props.pathname} to={`/admin/accounts`}
                                             className="list-group-item d-flex justify-content-between align-items-center">
                                Accounts
                                <span className="badge badge-danger badge-pill">A</span>
                            </SmartNavLink>)
                            : ''}


                        {(props.administrator)
                            ? (<SmartNavLink pathname={props.pathname} to={`/admin/graders`}
                                             className="list-group-item d-flex justify-content-between align-items-center">
                                Graders
                                <span className="badge badge-danger badge-pill">A</span>
                            </SmartNavLink>)
                            : ''}
                    </ul>


                </div>
            ) : '')}

    </div>

);


TeacherSidebar.propTypes = {
    pathname: PropTypes.string,

    administrator: PropTypes.bool
};

export default TeacherSidebar;
