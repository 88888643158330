import React from 'react';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


import './styles.css';
import {connect} from 'react-redux';
import LoadingSpinner from "../../components/LoadingSpinner";

import {loadTasks} from '../../store/teacher-tasks';
import {loadArticles} from '../../store/teacher-articles';
import {createEvent, loadEvents, updateEvent} from '../../store/teacher-events';


import Select from 'react-select';
import TeacherSidebar from "../../components/TeacherSidebar";
import Card from "../../components/Card";


class TeacherAddEventPage extends React.Component {
    state = {
        name: '',
        articles: [],
        tasks: [],

        startTime: new Date(),
        endTime: new Date(),

        codeProtected: false,
        analysisMode: false,

        analysisStartTime: new Date(),
        analysisEndTime: new Date(),

        visibleTests: false,
        visibleResults: false,
        scoringMode: 'ioi',

        invitees: '',
        moderators: '',

        eventId: undefined,
        internalError: null
    };

    componentWillMount() {

        if (!this.props.tasks && !this.props.isLoadingTasks) {
            this.props.loadTasks();
        }

        if (!this.props.articles && !this.props.isLoadingArticles) {
            this.props.loadArticles();
        }

        if (!this.props.events && !this.props.isLoadingEvents) {
            this.props.loadEvents();
        }

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            this.setState({
                eventId: this.props.match.params.id
            }, () => {

                this.loadEventContents();
            });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.events) {
            if (nextProps && nextProps.match && nextProps.match.params && nextProps.match.params.id) {
                let newEventId = nextProps.match.params.id;

                if (this.state.eventId != newEventId) {
                    this.setState({eventId: newEventId}, () => {
                        this.loadEventContents(nextProps);
                    });

                } else {
                    this.loadEventContents(nextProps);
                }
            }
        }
    }


    loadEventContents(newProps) {
        let props = newProps || this.props;
        let eventId = parseInt(this.state.eventId, 10);

        if (!props || !props.events) {
            return /* for now */;
        }

        let event = props.events.find(e => e.id == eventId);

        if (event && event.id == eventId) {

            this.setState({
                name: event.name,
                articles: event.articles.map(a => ({value: a.id, label: a.name})),
                tasks: event.tasks.map(t => ({value: t.id, label: t.name, points: t.points})),

                startTime: event.startTime,
                endTime: event.endTime,

                codeProtected: (event.code ? true : false),

                analysisMode: event.analysisMode ? true : false,
                analysisStartTime: event.analysisStartTime,
                analysisEndTime: event.analysisEndTime,

                visibleTests: event.visibleTests ? true : false,
                visibleResults: event.visibleResults ? true : false,
                scoringMode: event.scoringMode,

                invitees: (event.invitees && event.invitees.length > 0) ? event.invitees.join(';') : '',
                moderators: (event.moderators && event.moderators.length > 0) ? event.moderators.join(';') : '',

                loadedEventId: event.id
            });
        }

    }

    eventData() {

        let {articles, tasks, scoringMode, visibleTests, visibleResults} = this.state;
        let {name, codeProtected, startTime, endTime} = this.state;
        let {analysisMode, analysisStartTime, analysisEndTime} = this.state;
        let {invitees, moderators} = this.state;

        let inviteOnly = (invitees && invitees.length > 0);

        return {
            name, codeProtected, startTime, endTime, scoringMode, visibleTests, visibleResults,
            analysisMode, analysisStartTime, analysisEndTime,

            inviteOnly, invitees, moderators,
            articles: articles.map(a => a.value).join(';'), tasks: tasks.map(t => (t.value + ':' + t.points)).join(';')
        };
    }

    options(type) {

        let data = (this.props[type] || []).filter(a => {

            if (a.readyForUsage !== undefined && !a.readyForUsage) {
                return false;
            }

            return true;

        }).map(a => ({
            value: a.id,
            label: a.name
        }));

        return data;
    }


    updateTasksList(newTaskList) {

        let pointsUpdate = (this.state.tasks.length > 0 ? this.state.tasks[this.state.tasks.length - 1].points : 100);

        if (newTaskList.length > 0 && !newTaskList[newTaskList.length - 1].points) {
            newTaskList[newTaskList.length - 1].points = pointsUpdate;
        }

        return newTaskList;
    }


    updateTaskPoints(taskId, taskPoints) {

        if (!taskPoints || isNaN(taskPoints)) {
            taskPoints = 0;
        }


        let tasks = this.state.tasks.map(task => {

            if (task.value == taskId) {
                task.points = taskPoints;
            }

            return task;
        });

        return tasks;
    }


    submitForm() {

        this.setState({internalError: null}, () => {

            if (!this.state.name || !this.state.startTime || !this.state.endTime) {
                this.setState({internalError: 'Please complete all fields and try again!'});
                return /* don't submit form */;
            }

            if (!this.state.eventId) {
                this.props.createEvent(this.eventData());
            } else {
                this.props.updateEvent(this.state.eventId, this.eventData());
            }

        });
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <TeacherSidebar
                            event={(this.state.eventId && this.props.events) ? (this.props.events.find(e => e.id == this.state.eventId)) : null}
                            pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}
                            administrator={this.props.accountType === 'admin'}/>
                    </div>

                    <div className="col-12 col-md-8 col-lg-9">
                        {this.renderPage()}
                    </div>
                </div>
            </div>
        );
    }


    renderPage() {


        return (
            <div>

                {(this.state.eventId && !this.props.events) ? <LoadingSpinner/> : (
                    <form className="form data-form mt-4" style={{opacity: this.props.isUpdatingEvent ? 0.3 : 1}}
                          onSubmit={(event) => {
                              event.preventDefault();
                              this.submitForm();
                          }}>
                        <h2 className="text-center mt-1 mb-4">{this.state.eventId ? 'Edit event' : 'Add event'}</h2>

                        {(this.state.internalError || this.props.failedUpdatingEvent) ?
                            <p className="form-error">{this.state.internalError || this.props.error || 'Failed to save event. Please try again.'}</p> : ''}

                        <Card title="Basic info">
                            <div className="form-group">
                                <label htmlFor="name">Event name</label>
                                <input type="text" className="form-control" id="name" name="name" autoFocus
                                       placeholder="Enter a name" value={this.state.name}
                                       onChange={event => this.setState({name: event.target.value})}/>
                            </div>


                            <div className="form-group">
                                <label htmlFor="articles">Articles</label>
                                <div>
                                    <Select value={this.state.articles}
                                            onChange={value => this.setState({articles: value})}
                                            options={this.options('articles')} isMulti={true}/>
                                </div>
                            </div>


                            <div className="form-group">
                                <label htmlFor="tasks">Tasks</label>
                                <div>
                                    <Select value={this.state.tasks}
                                            onChange={value => this.setState({tasks: this.updateTasksList(value)})}
                                            options={this.options('tasks')} isMulti={true}/>
                                </div>
                            </div>


                        </Card>


                        <Card title="Task setup">


                            <div className="form-group">
                                <label htmlFor="scoringMode">Scoring mode</label>

                                <select className="form-control" id="scoringMode" name="scoringMode"
                                        value={this.state.scoringMode}
                                        onChange={event => this.setState({scoringMode: event.target.value})}>

                                    {(this.props.scoringModes || []).map(mode => (
                                        <option key={mode} value={mode}>{mode}</option>
                                    ))}
                                </select>
                            </div>

                            <p style={{marginTop: '25px'}}>Task points</p>
                            {this.state.tasks.map(task => (

                                <div key={task.value} className="form-group row">
                                    <label for={"taskPoints" + task.value}
                                           className="col-sm-4 col-form-label text-right">{task.label}</label>
                                    <div className="col-sm-4">
                                        <input type="text" className="form-control" id={"taskPoints" + task.value}
                                               value={task.points}
                                               onChange={event => this.setState({tasks: this.updateTaskPoints(task.value, event.target.value)})}/>
                                    </div>
                                </div>

                            ))}


                        </Card>


                        <Card title="Timing">
                            <div className="form-group">
                                <label htmlFor="startTime">Start time</label>

                                <div>
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.startTime}
                                        onChange={date => this.setState({startTime: date})}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        timeCaption="time"
                                    />
                                </div>
                            </div>


                            <div className="form-group">
                                <label htmlFor="startTime">End time</label>
                                <div>
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.endTime}
                                        onChange={date => this.setState({endTime: date})}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        timeCaption="time"
                                    />
                                </div>
                            </div>


                            <div className="form-group">
                                <label htmlFor="visibleResults">Public results</label>

                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="visibleResults"
                                           name="visibleResults" checked={this.state.visibleResults}
                                           onChange={() => this.setState({visibleResults: !this.state.visibleResults})}/>
                                    <label className="custom-control-label" htmlFor="visibleResults">Results are
                                        publicly visible</label>
                                </div>
                            </div>


                            <div className="form-group">
                                <label htmlFor="visibleTests">Public test data</label>

                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="visibleTests"
                                           name="visibleTests" checked={this.state.visibleTests}
                                           onChange={() => this.setState({visibleTests: !this.state.visibleTests})}/>
                                    <label className="custom-control-label" htmlFor="visibleTests">Tests are publicly
                                        visible during event (WARNING)</label>
                                </div>
                            </div>


                            <div className="form-group">
                                <label htmlFor="analysisMode">Analysis mode</label>

                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="analysisMode"
                                           name="analysisMode" checked={this.state.analysisMode}
                                           onChange={() => this.setState({analysisMode: !this.state.analysisMode})}/>
                                    <label className="custom-control-label" htmlFor="analysisMode">Enable analysis
                                        mode</label>
                                </div>
                            </div>


                            {(this.state.analysisMode) ? (
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="startTime">Analysis start time</label>
                                        <div>
                                            <DatePicker
                                                className="form-control"
                                                selected={this.state.analysisStartTime}
                                                onChange={date => this.setState({analysisStartTime: date})}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                timeCaption="time"
                                            />
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="startTime">Analysis end time</label>
                                        <div>
                                            <DatePicker
                                                className="form-control"
                                                selected={this.state.analysisEndTime}
                                                onChange={date => this.setState({analysisEndTime: date})}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                timeCaption="time"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : ''}



                            <div className="form-group">
                                <label htmlFor="invitees">Invitees</label>
                                <div>
                                    <input type="text" className="form-control" id="invitees" name="invitees"
                                           placeholder="Semicolon separated list of usernames" value={this.state.invitees}
                                           onChange={event => this.setState({invitees: event.target.value})}/>
                                </div>
                            </div>


                            <div className="form-group">
                                <label htmlFor="moderators">Moderators</label>
                                <div>
                                    <input type="text" className="form-control" id="moderators" name="moderators"
                                           placeholder="Semicolon separated list of usernames" value={this.state.moderators}
                                           onChange={event => this.setState({moderators: event.target.value})}/>
                                </div>
                            </div>
                        </Card>


                        <div className="text-right">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                )}

            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        error: state.teacherEvents.lastError,
        events: state.teacherEvents.events,
        scoringModes: state.teacherEvents.scoringModes,
        isUpdatingEvent: state.teacherEvents.isUpdatingEvent,
        failedUpdatingEvent: state.teacherEvents.failedUpdatingEvent,

        articles: state.teacherArticles.articles,
        tasks: state.teacherTasks.tasks,

        isLoadingEvents: state.teacherEvents.isLoadingEvents,
        failedLoadingEvents: state.teacherEvents.failedLoadingEvents,

        isLoadingTasks: state.teacherTasks.isLoadingTasks,
        failedLoadingTasks: state.teacherTasks.failedLoadingTasks,

        isLoadingArticles: state.teacherArticles.isLoadingArticles,
        failedLoadingArticles: state.teacherArticles.failedLoadingArticles,

        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadEvents: () => {
            dispatch(loadEvents());
        },
        loadTasks: () => {
            dispatch(loadTasks());
        },
        loadArticles: () => {
            dispatch(loadArticles());
        },
        createEvent: (settings) => {
            dispatch(createEvent(settings));
        },
        updateEvent: (eventId, settings) => {
            dispatch(updateEvent(eventId, settings));
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherAddEventPage);
