import React from 'react';
import {Redirect, Route} from 'react-router-dom';

const RestrictedRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => {

        if (rest.hasOwnProperty('isAuthenticated')) {
            props.isAuthenticated = rest.isAuthenticated;
        } else {
            props.isAuthenticated = undefined;
        }

        return (
            rest.allow
                ? <Component {...props} />
                : <Redirect to={rest.fallback}/>
        );
    }}
    />
);

export default RestrictedRoute;
