import React from 'react';
import {Link} from 'react-router-dom';
import config from '../../config/default.json';

import {connect} from 'react-redux';
import {loadEvents} from '../../store/student-events';

import "./styles.css";
import LoadingSpinner from "../../components/LoadingSpinner";

class StudentEventsPage extends React.Component {

    componentWillMount() {
        if (!this.props.events && !this.props.isLoadingEvents) {
            this.props.loadEvents();
        }
    }


    renderEvent(event) {

        return (
            <div key={event.id} className={"event text-left event-" + event.status}>
                <div className="row">
                    <div className="col-12 col-md-7 col-lg-8">
                        <h3>{event.name}</h3>
                        <p style={{margin: '3px 0 0 0'}}>Start time: {event.startTimeStandard}</p>
                        <p style={{margin: '1px 0 3px 0'}}>End time: {event.endTimeStandard}</p>
                    </div>

                    <div className="col-12 col-md-5 col-lg-4">
                        <div className="text-center d-flex align-items-center justify-content-center"
                             style={{height: "100%"}}>
                            <p style={{marginBottom: 0}} className="text-center">

                                <Link to={"/student/events/" + event.id} className="btn btn-primary btn-md"
                                      style={{marginBottom: '5px'}}>Join event</Link><br/>
                                <span
                                    style={{opacity: 0.75}}>{(event.codeProtected) ? 'Code required' : 'Anyone can join'}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col text-center">

                        <h2 className="mb-4">{config.APP_NAME}</h2>
                        {(!this.props.events) ? <LoadingSpinner/> : ''}

                        {(this.props.events && this.props.events.length === 0)
                            ? (<div><p className="mt-4 mb-5 py-3">You don't have any events.<br/>Please check back later
                                for more events available to everyone.</p></div>)
                            : ''}


                        {(this.props.events && this.props.events.length > 0) ? (
                            <div>
                                {this.props.events.map(event => this.renderEvent(event))}
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        events: state.studentEvents.events,
        isLoadingEvents: state.studentEvents.isLoadingEvents,
        failedLoadingEvents: state.studentEvents.failedLoadingEvents
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadEvents: () => {
            dispatch(loadEvents());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentEventsPage);
