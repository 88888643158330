import React from 'react';

import StudentEventSidebar from '../../components/StudentEventSidebar';
import StudentQuestionModal from '../../components/StudentQuestionModal';

import {loadEvent, loadQuestions, standardEventUpdates} from '../../store/student-events';

import {connect} from 'react-redux';

import config from '../../config/default.json';
import LoadingSpinner from "../../components/LoadingSpinner";

class StudentEventQuestionPage extends React.Component {

    state = {
        questionModalIsActive: false
    };

    componentWillMount() {
        let eventId = this.props.match.params.id;

        if ((!this.props.event || this.props.event.id != eventId) && !this.props.isLoadingEvent) {
            if (this.props.event) {
                this.props.loadEvent(eventId);
            } else {
                setTimeout(() => {
                    this.props.loadEvent(eventId);
                }, 1000);
            }
        }

        if (this.props.event) {
            this.loadData(this.props);
        }
    }


    componentDidMount() {
        let eventId = this.props.match.params.id;

        if (this.props.event && this.props.event.id == eventId) {
            this.props.standardEventUpdates(eventId);
        }

        this.intervalId = setInterval(() => {

            if (this.props.event && this.props.event.id == eventId) {
                this.props.standardEventUpdates(eventId);
            }
        }, 40 * 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }


    componentWillReceiveProps(newProps) {

        let eventId = this.props.match.params.id;
        if (newProps.event && newProps.event.id == eventId && newProps.event.format && newProps.event.status !== 'running' && newProps.event.status !== 'analysis') {
            this.props.history.push(`/student/events/${eventId}`)
        }

        if (newProps.event && newProps.event.id != eventId && !newProps.isLoadingEvent) {
            this.props.loadEvent(eventId);
        }

        this.loadData(newProps);
    }


    loadData(newProps) {

        let eventId = this.props.match.params.id;
        if (newProps.event && newProps.event.id == eventId && newProps.questions == null && !newProps.isLoadingQuestions && !newProps.failedLoadingQuestions) {

            setTimeout(() => {
                this.props.loadQuestions();
            }, 500);
        }

    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col text-center">

                        {(!this.props.event) ? (
                            <div>
                                <h2 className="mb-4">{config.APP_NAME}</h2>
                                <LoadingSpinner/>
                            </div>) : ''}


                        {(this.props.event && this.props.questions === null) ? (
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-3">
                                    <StudentEventSidebar event={this.props.event}
                                                         pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}/>
                                </div>

                                <div className="col-12 col-md-8 col-lg-9">
                                    <LoadingSpinner/>
                                </div>
                            </div>) : ''}


                        {(this.props.event && this.props.questions !== null) ? (
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-3">
                                    <StudentEventSidebar event={this.props.event}
                                                         pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}/>
                                </div>

                                <div className="col-12 col-md-8 col-lg-9">
                                    {this.renderPage()}
                                </div>
                            </div>) : ''}
                    </div>
                </div>
            </div>
        );

    }


    renderPage() {

        return (
            <div className="text-left">
                <h2 className="mb-4">{this.props.event.name}</h2>

                {(this.props.questions.map(question => (

                    <div key={question.id} style={{marginBottom: '30px'}}>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title mb-3"><span
                                    className="badge badge-primary">Q</span> {question.question}</h5>
                                <p className="card-text">Answer: {question.answer}</p>

                                <p className="text-right text-muted mb-0">{question.askedOnStandard}</p>
                            </div>
                        </div>
                    </div>
                )))}


                {(this.props.questions.length === 0)
                    ? <p className="mt-5 mb-5 text-center">No questions to present.<br/>Please check back later.</p>
                    : ''}


                <div className="text-right">
                    <button type="button" className="btn btn-primary"
                            onClick={() => this.setState({questionModalIsActive: true})}>Ask question
                    </button>

                    <StudentQuestionModal open={this.state.questionModalIsActive}
                                          eventId={this.props.event.id}
                                          onClose={() => {
                                              this.setState({questionModalIsActive: false});
                                          }}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        event: state.studentEvents.event,
        isLoadingEvent: state.studentEvents.isLoadingEvent,
        failedLoadingEvent: state.studentEvents.failedLoadingEvent,

        questions: state.studentEvents.questions,
        isLoadingQuestions: state.studentEvents.isLoadingQuestions,
        failedLoadingQuestions: state.studentEvents.failedLoadingQuestions,

        error: state.studentEvents.lastError
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadEvent: (eventId) => {
            dispatch(loadEvent(eventId));
        },
        standardEventUpdates: (eventId) => {
            dispatch(standardEventUpdates(eventId));
        },
        loadQuestions: () => {
            dispatch(loadQuestions());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentEventQuestionPage);
