import React from 'react';
import {Link} from 'react-router-dom';
import config from '../../config/default.json';

import {connect} from 'react-redux';
import {loadArticles} from '../../store/teacher-articles';

import LoadingSpinner from "../../components/LoadingSpinner";
import TeacherSidebar from "../../components/TeacherSidebar";

class TeacherArticlesPage extends React.Component {

    componentWillMount() {
        if (!this.props.articles && !this.props.isLoadingArticles) {
            this.props.loadArticles();
        }
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <TeacherSidebar
                            pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}
                            administrator={this.props.accountType === 'admin'}/>
                    </div>

                    <div className="col-12 col-md-8 col-lg-9">
                        {this.renderPage()}
                    </div>
                </div>
            </div>
        );
    }


    renderPage() {


        return (
            <div>

                <h2 className="mb-4">{config.APP_NAME}</h2>
                {(!this.props.articles) ? <LoadingSpinner/> : ''}

                {(this.props.articles && this.props.articles.length === 0)
                    ? (<div><p className="mt-4 mb-5 py-3">You don't have any articles.<br/>Start by creating one using
                        the button below.</p></div>)
                    : ''}


                {(this.props.articles && this.props.articles.length > 0) ? (
                    <div className="row">
                        <div className="col-12">
                            <table className="table table-sm table-striped">
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Last update</th>
                                    <th>Private</th>

                                    {(this.props.accountType === 'admin')
                                        ? (<th>Status</th>)
                                        : ''}
                                </tr>
                                </thead>

                                <tbody>
                                {(this.props.articles.map(article => (
                                    <tr key={article.id} style={{cursor: 'pointer'}}
                                        onClick={() => this.props.history.push(`/teacher/articles/edit/${article.id}`)}>
                                        <td>{article.id}</td>
                                        <td>{article.name}</td>
                                        <td>{article.updatedAtStandard}</td>
                                        <td>{article.privateItem ? 'Private' : 'Public'}</td>

                                        {(this.props.accountType === 'admin')
                                            ? (<td>


                                                {(article.owner) ? (
                                                    <span className="badge badge-pill badge-primary">Owner</span>) : ''}
                                                {(article.privateItem) ? (
                                                    <span className="badge badge-pill badge-info">Private</span>) : ''}
                                                {(!article.privateItem && article.approvedByAdmin) ? (<span
                                                    className="badge badge-pill badge-success">Public</span>) : ''}
                                                {(!article.privateItem && !article.approvedByAdmin) ? (<span
                                                    className="badge badge-pill badge-danger">Pending approval</span>) : ''}

                                            </td>)
                                            : ''}
                                    </tr>
                                )))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : ''}


                <p className="mt-4 text-right">
                    <Link to="/teacher/articles/add" className="btn btn-primary">Add article</Link>
                </p>

            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        articles: state.teacherArticles.articles,
        isLoadingArticles: state.teacherArticles.isLoadingArticles,
        failedLoadingArticles: state.teacherArticles.failedLoadingArticles,

        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadArticles: () => {
            dispatch(loadArticles());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherArticlesPage);
