import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import './styles.css';

Modal.setAppElement('#root');


class SubmissionTestDataModal extends React.Component {
    render() {

        return (
            <Modal isOpen={this.props.open} onRequestClose={() => this.props.onClose()}
                   className="submission_test_style" overlayClassName="submission_test_overlay">
                <div>

                    <h2>Test data</h2>

                    <div className="card">
                        <div className="card-header">Input</div>
                        <div className="card-body">
                            <pre>{this.props.test.input}</pre>
                        </div>
                    </div>


                    <div className="card">
                        <div className="card-header">Your Output</div>
                        <div className="card-body">
                            <pre>{this.props.test.output ? this.props.test.output : '[no output]'}</pre>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">Correct output</div>
                        <div className="card-body">
                            <pre>{this.props.test.correctOutput ? this.props.test.correctOutput : '[no output]'}</pre>
                        </div>
                    </div>

                    <div className="text-right" style={{marginTop: '20px', marginBottom: '15px'}}>
                        <button onClick={(event) => {
                            event.preventDefault();
                            this.props.onClose();
                        }} className="btn btn-danger">Close
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}


SubmissionTestDataModal.propTypes = {
    test: PropTypes.object,
    open: PropTypes.bool,

    onClose: PropTypes.func
};

export default SubmissionTestDataModal;
