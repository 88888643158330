import React from 'react';

import './styles.css';

import request from '../../lib/request';

import {connect} from 'react-redux';
import LoadingSpinner from "../../components/LoadingSpinner";

import {loadEvents} from '../../store/teacher-events';
import TeacherSidebar from "../../components/TeacherSidebar";
import SubmissionData from "../../components/SubmissionData";


class TeacherEventSubmissionPage extends React.Component {
    state = {
        isLoadingSubmission: false,
        failedLoadingSubmission: false,

        event: null,
        eventId: null,

        submissionId: null,
        submission: null
    };

    componentWillMount() {

        if (!this.props.events && !this.props.isLoadingEvents) {
            this.props.loadEvents();
        }

        this.setupEvent(this.props);

        let newEventId = this.props.match.params.id;
        let newSubmissionId = this.props.match.params.submissionId;
        this.loadSubmission(newEventId, newSubmissionId);
    }

    setupEvent(nextProps) {
        let event = null;

        let newEventId = nextProps.match.params.id;
        let newSubmissionId = nextProps.match.params.submissionId;

        if (nextProps.events && nextProps.events.find(e => (e.id == newEventId))) {
            event = nextProps.events.find(e => (e.id == newEventId));
        }

        if (event) {
            this.setState({
                event: event,
                eventId: newEventId,

                submissionId: newSubmissionId
            });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps && nextProps.match && nextProps.match.params && nextProps.match.params.id) {
            let newEventId = nextProps.match.params.id;
            let newSubmissionId = nextProps.match.params.submissionId;

            this.setupEvent(nextProps);

            if (!this.state.submissionId || this.state.submissionId != newSubmissionId) {
                this.loadSubmission(newEventId, newSubmissionId);
            }
        }
    }

    loadSubmission(eventId, submissionId) {

        if (!eventId || !submissionId) {
            return /* skip */;
        }


        this.setState({
            submission: null,
            isLoadingSubmission: true,
            failedLoadingSubmission: false
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[eventId];

        return request.get('/teacher/events/' + eventId + '/submission/' + submissionId, {
            headers: {
                Authentication: authenticationToken,
                'Event-Token': eventToken
            }
        })
            .then(({data}) => {

                //simulate delay, for loading effect
                setTimeout(() => {

                    this.setState({
                        submission: data,

                        isLoadingSubmission: false,
                        failedLoadingSubmission: false
                    });

                }, 100);
            })
            .catch((error) => {
                this.setState({
                    isLoadingSubmission: false,
                    failedLoadingSubmission: true
                })
            });
    }


    render() {

        return (
            <div className="container submission-container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <TeacherSidebar event={this.state.event}
                                        pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}
                                        administrator={this.props.accountType === 'admin'}/>
                    </div>

                    <div className="col-12 col-md-8 col-lg-9">
                        {(!this.state.event || !this.state.submissionId || this.state.submission === null) ?
                            <LoadingSpinner/> : (
                                <div>{this.renderPage()}</div>
                            )}
                    </div>
                </div>
            </div>
        );
    }


    renderPage() {

        return (
            <div>
                <h2 className="mb-4">{this.state.event.name}</h2>
                <SubmissionData submission={this.state.submission}/>
            </div>

        );
    }
}

const mapStateToProps = function (state) {
    return {
        error: state.teacherEvents.lastError,
        events: state.teacherEvents.events,

        isLoadingEvents: state.teacherEvents.isLoadingEvents,
        failedLoadingEvents: state.teacherEvents.failedLoadingEvents,

        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadEvents: () => {
            dispatch(loadEvents());
        }

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherEventSubmissionPage);
