import request from '../lib/request.js';
import {LOCATION_CHANGE} from "connected-react-router";

export const LOAD_SUBMISSION_STARTED = 'studentSubmissions/LOAD_SUBMISSION_STARTED';
export const LOAD_SUBMISSION_FAILED = 'studentSubmissions/LOAD_SUBMISSION_FAILED';
export const LOAD_SUBMISSION_FINISHED = 'studentSubmissions/LOAD_SUBMISSION_FINISHED';

export const CLEAR_SUBMISSION_DATA = 'studentSubmissions/CLEAR_SUBMISSION_DATA';



export const LOAD_SUBMISSIONS_STARTED = 'studentSubmissions/LOAD_SUBMISSIONS_STARTED';
export const LOAD_SUBMISSIONS_FAILED = 'studentSubmissions/LOAD_SUBMISSIONS_FAILED';
export const LOAD_SUBMISSIONS_FINISHED = 'studentSubmissions/LOAD_SUBMISSIONS_FINISHED';


const initialState = {
    submission: null,
    submissions: null,

    isLoadingSubmission: false,
    failedLoadingSubmission: false,

    isLoadingSubmissions: false,
    failedLoadingSubmissions: false,


    lastError: null
};

export default (state = initialState, action) => {
    switch (action.type) {

        case LOCATION_CHANGE:
            return {
                ...state,
                failedLoadingSubmission: false,
                failedLoadingSubmissions: false
            };

        case LOAD_SUBMISSION_STARTED:
            return {
                ...state,
                lastError: null,
                submission: null,
                isLoadingSubmission: true,
                failedLoadingSubmission: false
            };


        case CLEAR_SUBMISSION_DATA:
            return {
                ...state,
                lastError: null,
                submission: null,
                submissions: null,
                isLoadingSubmission: false,
                failedLoadingSubmission: false,

                isLoadingSubmissions: false,
                failedLoadingSubmissions: false
            };


        case LOAD_SUBMISSION_FAILED:
            return {
                ...state,
                lastError: null,
                submission: null,
                isLoadingSubmission: false,
                failedLoadingSubmission: new Date()
            };

        case LOAD_SUBMISSION_FINISHED:
            return {
                ...state,
                lastError: null,
                submission: action.submission,
                submissions: (state.submissions ? state.submissions.map(t => {

                    if (t.submissions && t.task && t.task.id == action.submission.taskId) {


                        let foundSubmission = false;
                        let change = Object.assign({}, action.submission);

                        //data not returned by /submissions
                        //only for individual submissions
                        if (change.sourceCode) {
                            delete change.sourceCode;
                        }

                        if (change.result) {
                            delete change.result;
                        }

                        t.submissions = t.submissions.slice().map(s => {
                            if (s.id == action.submission.id) {
                                foundSubmission = true;
                                return change;
                            } else {
                                return s;
                            }
                        });

                        if (!foundSubmission) {
                            t.submissions = [change].concat(t.submissions);
                        }

                        return Object.assign({}, t);
                    }

                    return t;
                }) : state.submissions),
                isLoadingSubmission: false,
                failedLoadingSubmission: false
            };


        case LOAD_SUBMISSIONS_STARTED:
            return {
                ...state,
                lastError: null,
                submissions: null,
                isLoadingSubmissions: true,
                failedLoadingSubmissions: false
            };


        case LOAD_SUBMISSIONS_FAILED:
            return {
                ...state,
                lastError: null,
                submissions: null,
                isLoadingSubmissions: false,
                failedLoadingSubmissions: new Date()
            };

        case LOAD_SUBMISSIONS_FINISHED:
            return {
                ...state,
                lastError: null,
                submissions: action.submissions,
                isLoadingSubmissions: false,
                failedLoadingSubmissions: false
            };


        default:
            return state
    }
}


export const loadSubmission = (submissionId, skipClear) => {


    return (dispatch, getState) => {
        let event = getState().studentEvents.event;

        if (event && event.id) {

            if (!skipClear) {
                dispatch({
                    type: LOAD_SUBMISSION_STARTED
                });
            }

            let authenticationToken = localStorage.getItem('App-Authentication-Token');
            let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[event.id];

            return request.get('/student/events/' + event.id + '/submission/' + submissionId, {
                headers: {
                    Authentication: authenticationToken,
                    'Event-Token': eventToken
                }
            })
                .then(({data}) => {

                    dispatch({
                        type: LOAD_SUBMISSION_FINISHED,
                        submission: data
                    });
                })

                .catch((error) => {

                    if (!skipClear) {
                        dispatch({
                            type: LOAD_SUBMISSION_FAILED
                        });
                    }
                });

        }
    }
};


export const loadSubmissions = (skipClear) => {

    return (dispatch, getState) => {
        let event = getState().studentEvents.event;

        if (event && event.id) {
            if (!skipClear) {
                dispatch({
                    type: LOAD_SUBMISSIONS_STARTED
                });
            }

            let authenticationToken = localStorage.getItem('App-Authentication-Token');
            let eventToken = JSON.parse(localStorage.getItem('App-Event-Token') || '{}')[event.id];

            return request.get('/student/events/' + event.id + '/submissions', {
                headers: {
                    Authentication: authenticationToken,
                    'Event-Token': eventToken
                }
            })
                .then(({data}) => {


                    dispatch({
                        type: LOAD_SUBMISSIONS_FINISHED,
                        submissions: data
                    });
                })
                .catch((error) => {

                    if (!skipClear) {
                        dispatch({
                            type: LOAD_SUBMISSIONS_FAILED
                        });
                    }
                });

        }
    }
};