import React from 'react';

import PropTypes from 'prop-types';


const Card = (props) => (
    <div className="mt-3 mb-5">
        <div className="card">
            <div className={"card-header" + (props.classes ? ' ' + props.classes : '')}>
                {props.title}
            </div>
            <div className="card-body">
                {props.children}
            </div>
        </div>

    </div>

);


Card.propTypes = {
    title: PropTypes.string
};

export default Card;
