import React from 'react';
import {Link} from 'react-router-dom';
import config from '../../config/default.json';

import {connect} from 'react-redux';
import {loadEvents} from '../../store/teacher-events';

import LoadingSpinner from "../../components/LoadingSpinner";
import TeacherSidebar from "../../components/TeacherSidebar";

class TeacherEventsPage extends React.Component {

    componentWillMount() {
        if (!this.props.events && !this.props.isLoadingEvents) {
            this.props.loadEvents();
        }
    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <TeacherSidebar
                            pathname={(this.props.location && this.props.location.pathname ? this.props.location.pathname : '')}
                            administrator={this.props.accountType === 'admin'}/>
                    </div>

                    <div className="col-12 col-md-8 col-lg-9">
                        {this.renderPage()}
                    </div>
                </div>
            </div>
        );
    }


    renderPage() {

        return (
            <div>

                <h2 className="mb-4">{config.APP_NAME}</h2>
                {(!this.props.events) ? <LoadingSpinner/> : ''}

                {(this.props.events && this.props.events.length === 0)
                    ? (<div><p className="mt-4 mb-5 py-3">You don't have any events.<br/>Start by creating one using the
                        button below.</p></div>)
                    : ''}


                {(this.props.events && this.props.events.length > 0) ? (
                    <div className="row">
                        <div className="col-12">
                            <table className="table table-sm table-striped">
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Start time</th>
                                    <th>End time</th>
                                </tr>
                                </thead>

                                <tbody>
                                {(this.props.events.map(event => (
                                    <tr key={event.id} style={{cursor: 'pointer'}}
                                        onClick={() => this.props.history.push(`/teacher/events/edit/${event.id}`)}>
                                        <td>{event.id}</td>
                                        <td>{event.name}</td>
                                        <td>{event.startTimeStandard}</td>
                                        <td>{event.endTimeStandard}</td>
                                    </tr>
                                )))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : ''}


                <p className="mt-4 text-right">
                    <Link to="/teacher/events/add" className="btn btn-primary">Add event</Link>
                </p>

            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        events: state.teacherEvents.events,
        isLoadingEvents: state.teacherEvents.isLoadingEvents,
        failedLoadingEvents: state.teacherEvents.failedLoadingEvents,

        accountType: state.auth.accountType
    }
};

function mapDispatchToProps(dispatch) {
    return {
        loadEvents: () => {
            dispatch(loadEvents());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherEventsPage);
