import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import './styles.css';
import request from '../../lib/request';

Modal.setAppElement('#root');


class UploadModal extends React.Component {
    fileRef = React.createRef();

    state = {
        filename: undefined,
        internalError: null
    };


    uploadFile() {

        if (this.fileRef.current.files.length > 0) {

            if (this.state.internalError) {
                this.setState({internalError: null});
            }

            let data = new FormData();
            data.append('data', this.fileRef.current.files[0]);

            let authenticationToken = localStorage.getItem('App-Authentication-Token');
            return request.post('/teacher/uploads', data, {
                headers: {
                    Authentication: authenticationToken,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(({data}) => {

                    this.setState({
                        filename: data.path
                    })
                })
                .catch((error) => {
                    let errorText = (error.response && error.response.data ? error.response.data.message : error) || error;
                    this.setState({
                        internalError: errorText || 'An error occurred while uploading the file. Please try again'
                    });
                });
        }
    }


    render() {

        return (
            <Modal isOpen={this.props.open} onRequestClose={() => this.props.onClose()} className="upload_modal_style"
                   overlayClassName="upload_modal_overlay">
                <h2>File upload</h2>
                <p>Select a file for uploading...</p>

                {(this.state.filename) ? (
                    <p style={{color: 'blue', margin: '30px 40px'}}>File upload complete.<br/>Please use the following
                        link in article/task contents:<br/><br/><span
                            style={{fontWeight: 'bold'}}>{this.state.filename}</span></p>
                ) : ''}

                {(this.state.internalError) ? (
                    <p style={{color: 'red', margin: '30px 40px', fontWeight: 'bold'}}>{this.state.internalError}</p>
                ) : ''}

                <form>
                    <div className="form-group">
                        <label htmlFor="uploadFile">File</label>
                        <input type="file" className="form-control-file" id="uploadFile" name="uploadFile"
                               ref={this.fileRef}/>
                    </div>
                </form>

                <div className="text-right" style={{marginTop: '20px'}}>
                    <button onClick={(event) => {
                        event.preventDefault();
                        this.uploadFile();
                    }} className="btn btn-primary">Upload</button>
                    &nbsp;
                    <button onClick={(event) => {
                        event.preventDefault();
                        this.props.onClose();
                    }} className="btn btn-danger">Close</button>
                </div>
            </Modal>
        );
    }
}


UploadModal.propTypes = {
    open: PropTypes.bool,

    onClose: PropTypes.func
};

export default UploadModal;
